.Author {
    display: flex;
    justify-content: flex-start;

    &-figure-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.Author-figure-object {
    border-radius: 100%;
    width: u(60);
    height: u(60);
    margin-right: u($spacing-unit);
}

