.Media-fill-object {
   max-height: u(700);
}

.Media-fill-image,
.Media-image {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center center;
}

.Media-fill-image,
.Media-image {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center center;
}
