/* Main */
.Nav--main {
    // Link
    .Nav-link {
        position: relative;
        color: $primary-color;
        padding: u($spacing-unit) u($spacing-unit--sm);

        @include mq($from: $viewport--lg) {
            padding: u($spacing-unit);
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: u(7);
            height: u(2);
            background: $primary-color;
            transform: scaleX(0);
            transition: transform $global-transition;
        }

        &.is-active,
        &:active,
        &:focus,
        &:hover {
            color: $white;

            &:before {
                transform: scaleX(1);
            }
        }

        &.is-webshop {
            padding: em($button-padding--t / 2, $button-padding--r, $button-padding--b / 2, $button-padding--l);
            border: u(1px) solid $primary-color;

            &:hover {
                color: $white;
                background-color: $primary-color;
                                
                &:before {
                    transform: scaleX(0);
                }
            }
        }
    }
}

.Header--light .Nav--main {
    .Nav-link {
        color: $white;

        &:before {
            background: $white;
        }

        &.is-active,
        &:active,
        &:focus,
        &:hover {
            color: $primary-color;
        }

        &.is-webshop {
            padding: em($button-padding--t / 3, $button-padding--r, $button-padding--b / 3, $button-padding--l);
            border: u(1px) solid $primary-color;
            color: $primary-color;
            
            &:hover {
                color: $white;
                background-color: $primary-color;
                                
                &:before {
                    transform: scaleX(0);
                }
            }
        }
    }
}
