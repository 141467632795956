.Toggle {
    @include dimensions($toggle-width, $toggle-height);
    @include position(relative);
    cursor: pointer;
    transform: rotate(0deg);
    background: $primary-color;
    color: $white;
    border-radius: 50%;

    &-item {
        @include position(absolute);
        @include transition(#{top, transform, opacity}, 250ms, ease-in-out);
        width: u(16);
        height: u($toggle-border-width);
        background-color: currentColor;
        border-radius: px($toggle-border-radius);
        display: block;
        transition: transform $global-transition;
        transform: rotate(0deg);
        left: 0;
        right: 0;
        margin: auto;

        &:nth-child(1) {
            top: u($toggle-height / 2 - $toggle-border-width / 2);
            transform: translate(0, u(-5)) rotate(0);

            .show-nav & {
                top: u($toggle-height / 2 - $toggle-border-width / 2);
                transform: translate(0, 0) rotate(135deg);
            }
        }

        &:nth-child(2) {
            top: u($toggle-height / 2 - $toggle-border-width / 2);

            .show-nav & {
                opacity: 0;
            }
        }

        &:nth-child(3) {
            top: u($toggle-height / 2 - $toggle-border-width / 2);
            width: u(8);
            transform: translate(u(4), u(5)) rotate(0);

            .show-nav & {
                transform: translate(0, 0) rotate(-135deg) scaleX(2);
            }
        }
    }

    &:hover .Toggle-item:nth-child(3) {
        transform: translate(0, u(5)) rotate(0) scaleX(2);
    }
}

.show-nav .Toggle:hover .Toggle-item:nth-child(3) {
    transform: translate(0, 0) rotate(-135deg) scaleX(2);
}
