/* Lang */
.Nav--lang {
    text-align: right;
    width: u(100);
    margin-right: 0;
    margin-left: auto;

    // List
    .Nav-list {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    // Link
    .Nav-link {
        color: $tertiary-color;
        padding: u($spacing-unit--xs, $spacing-unit--xs / 2);
        text-transform: uppercase;
        transition: color $global-transition;
        letter-spacing: 0.05em;

        &.is-active {
            color: $primary-color;
        }

        &:not(.is-active):hover {
            color: $primary-color;
        }
    }
}
