.List {
    &--none {
        padding: 0;
        margin: 0;
        list-style: none;
    }
}

.List-description {
    display: flex;

    &-label {
        font-weight: 300;
        flex-basis: u(120);
    }
}
