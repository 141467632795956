/* Header */
.Header {
    font-family: $font-nav;
    padding-top: u($spacing-unit);
    padding-bottom: u($spacing-unit);
    color: $white;
    width: 100%;
    transition: $global-ease $time;
    
    @include position(fixed, $top: 0);

    > .Container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &.Scrolling {
        background-color: $header-bg-color;
    }
}
