.Section {
    margin: u(60) 0;

    @include mq($from: $viewport--md) {
        margin: u(80) 0;
    }

    @include mq($from: $viewport--lg) {
        margin: u(100) 0;
    }

    &--light {
        background: $secondary-color;

        padding: u(60) 0;

        @include mq($from: $viewport--md) {
            padding: u(80) 0;
        }
    
        @include mq($from: $viewport--lg) {
            padding: u(100) 0;
        }
    }

    &--noSpacingTop {
        margin-top: 0 !important;
    }
}
