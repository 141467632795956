/* Components: Button */

// Namespace
$button-namespace:                    $namespace !default;

// Padding
$button-padding--t:                   15 !default;
$button-padding--r:                   $spacing-unit !default;
$button-padding--b:                   $button-padding--t !default;
$button-padding--l:                   $button-padding--r !default;

// Typography
$button-font-stack:                   $font-nav !default;
$button-font-weight:                  inherit !default;
$button-line-height:                  19px !default;
$button-font-size:                    16px !default;
$button-text-align:                   left !default;

// Borders
$button-border-width:                 0 !default;
$button-border-radius:                0 !default;

// States
$button-focus-width:                  0.2rem !default;
$button-focus-color:                  rgba($blue, 0.25) !default;
$button-focus-shadow:                 0 0 0 $button-focus-width $button-focus-color !default;

// Transition
$button-transition:                   (background-color, border-color, box-shadow, color) !default;

// Disabled
$button-disabled-opacity:             60% !default;
