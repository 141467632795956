.Cta-grid-item {
    height: 100%;
    width: 100%;

    &.u-bg-gold {
        h3 {
            color: $white;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    h3 {
        margin-bottom: 0;
    }

    &--center {
        .Cta-grid-link,
        .Cta-grid-inner {
            align-items: center;
        }

        .Cta-grid-image-object {
            max-width: u(200);
        }
    }

    &.u-bg-light {
        .Cta-grid-image-bg-object:after {
            background: $secondary-color;
            opacity: 0.85;
        }
    }

    &.u-bg-dark {
        .Cta-grid-image-bg-object:after {
            background: $quaternary-color;
            opacity: 0.85;
        }

        h3 {
            color: $primary-color;
        }
    }

    &.u-bg-gold {
        .Cta-grid-image-bg-object:after {
            background: $primary-color;
            opacity: 0.95;
        }

        .Cta-grid-subtitle {
            color: $white;
        }
    }
}

.Cta-grid-link,
.Cta-grid-inner {
    display: block;
    padding: u($spacing-unit * 1.5) u($spacing-unit);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    height: 100%;
    width: 100%;

    @include mq($from: $viewport--lg) {
        padding: u($spacing-unit * 2.5) u($spacing-unit--lg);
    }
}

.Cta-grid-content {
    position: relative;
    z-index: 1;
}

.Cta-grid-image-object {
    margin-bottom: u($spacing-unit--lg)
}

.Cta-grid-image-bg-object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.Cta-grid-image-bg-image {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
}

.Cta-grid-tag,
.Cta-grid-subtitle {
    font-family: $font-nav;
    font-size: u(16);
    letter-spacing: 1px;
    text-transform: uppercase;
}

.Cta-grid-subtitle {
    margin-bottom: u($spacing-unit--xs);
}

.Cta-grid-tag {
    padding: u($spacing-unit--xs) u($spacing-unit--sm);
    background: $primary-color;
    color: $white;
    display: inline-block;
    margin-bottom: u($spacing-unit);
}

.Cta-grid-subtitle {
    color: $primary-color;
}
