.Product {
    background-color: $secondary-color;

    &-title {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;

        &-image {
            max-width: u(550px);
            height: auto;
        }
    }

    &-item {
        padding: u($spacing-unit * 2.5);
        background-color: $white;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        margin-bottom: u($spacing-unit--lg);

        strong {
            font-family: $font-nav;
            color: $primary-color;
            letter-spacing: 1px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &-number {
            max-width: u(100px);
            height: auto;

            @include mq($until: $viewport--lg) {
                padding-top: u($spacing-unit--lg);
            }
        }

        &-images {
            margin-top: u($spacing-unit);
        }

        &-image {
            &-button {
                height: 100%;
                width: 100%;
            }

            &--big {
                display: flex;
                justify-content: center;

                img {
                    max-height: u(310px);
                    width: auto;
                }
            }

            &--small {
                @include aspect-ratio(1, 1);

                picture {
                    width: 100%;
                    height: 100%;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &-tag {
            position: absolute;
            top: u($spacing-unit);
            left: 0;
            display: inline;
            max-width: u(100px);
            padding: u($spacing-unit--sm) u($spacing-unit);
            font-family: Bebas Kai;
            font-size: u(14px);
            line-height: u(15px);
            color: $white;
            text-align: center;
            text-transform: uppercase;
            transform: translate(-50%);
            background-color: $quaternary-color;

            @include mq($until: $viewport--lg) {
                transform: translate(u(20px));
            }
        }

        &--has-tag {
            position: relative;
        }
    }
}
