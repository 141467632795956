.Hours-item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: u($spacing-unit--sm);
}

.Hours-day {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    flex-basis: u(70);
    font-weight: 700;
}

.Hours-hour {
    font-weight: 300;
}

.Hours-closing {
    color: $quaternary-color;
    opacity: 0.55;
    font-style: italic;
    font-weight: 300;
    margin-bottom: u($spacing-unit);
}
