.Read {
    background: $secondary-color;
    padding-bottom: u($spacing-unit--xl);
    margin-top: u(-$spacing-unit--xl);
}

.Read-spaced {
    margin: u($spacing-unit) 0;

    @include mq($from: $viewport--lg) {
        margin: u($spacing-unit--lg) 0;
    }
}

.Read-content {
    background: $white;
    max-width: u(920);
    padding: u($spacing-unit * 3 + $spacing-unit--xl + $spacing-unit--xl) u($spacing-unit) u($spacing-unit--lg);
    position: relative;
    z-index: 0;

    @include mq($from: $viewport--md) {
        padding: u($spacing-unit * 3 + $spacing-unit--xl + $spacing-unit--xl) u($spacing-unit--lg) u($spacing-unit--xl);
    }

    @include mq($from: $viewport--lg) {
        padding: u($spacing-unit * 3 + $spacing-unit--xl + $spacing-unit--xl) u($spacing-unit--xl) u($spacing-unit--xl);
    }

    &--small {
        padding-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            padding-top: u($spacing-unit--xl);
        }
    }
}

.Read-editor {
    ul {
        flex-wrap: wrap;
    }

    ul li {
        flex-basis: 50%;
        margin-bottom: u($spacing-unit--xs);
    }

    h3 {
        @include font-size($heading-size-3, $heading-spacing-3);
        text-transform: uppercase;
        color: $primary-color;
        font-weight: 400;
    }

    h4 {
        margin-bottom: u($spacing-unit);
    }

    h6 {
        text-transform: none;
        color: $quaternary-color;
    }

    h3 + h6 {
        margin-top: u(-$spacing-unit--sm);
    }
}

.Read-footer {
    margin-top: u($spacing-unit--lg);
    padding-top: u($spacing-unit--lg);
    border-top: u(1) solid rgba($primary-color, 0.4);
}

.Read-back {
    color: $quaternary-color;
    opacity: 0.4;
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
    position: relative;
    align-items: center;
    background: $white;
    width: 100%;
    padding-right: u($spacing-unit);
    transition: opacity $global-transition;
    z-index: 1;
    top: u($spacing-unit--xl * 2 + $spacing-unit);

    @include mq($from: $viewport--md) {
        padding-right: u($spacing-unit--lg);
    }

    @include mq($from: $viewport--lg) {
        position: absolute;
        background: none;
        top: u($spacing-unit--xl * 2 + $spacing-unit);
        right: u(-$spacing-unit--lg);
    }


    .Icon {
        width: u(16);
        margin-right: u($spacing-unit--sm);
    }

    &:hover,
    &:focus {
        opacity: 1;
    }
}

@import '7-theme/blocks-read/video';
@import '7-theme/blocks-read/quote';
