// Form
.Search-form {
    max-width: u(550);
    width: 100%;

    .Form-controls {
        margin-top: u(-37);
        margin-bottom: u($spacing-unit--sm);
    }

    .Form-item {
        margin: 0;
    }

    .Form-item.is-active {
        .Form-label {
            transform: translate3d(0, u(-20), 0);
            background: #262626;
        }
    }
}

.Search-input {
    font-family: $font-nav;
    border: u(1) solid rgba($white, 0.3);
    padding: u($spacing-unit--sm) u($spacing-unit);
    color: $white;
    transition: border $global-transition;

    &:focus {
        border-color: $white;
    }

    &:focus ~ .Search-button {
        color: $white;
    }
}

.Search-label {
    font-family: $font-nav;
    background: none;
    text-transform: uppercase;
    color: rgba($white, 0.3);
    margin-left: u($spacing-unit);
}

.Search-button {
    color: rgba($white, 0.3);
    position: absolute;
    right: u($spacing-unit);
    padding: u($spacing-unit--sm);
    margin-right: u(-$spacing-unit--sm);
    transition: color $global-transition;
}

.Search-icon {
    width: u(15);
}

// List
.Search-list {
    padding: 0;
    margin: 0;
    border-bottom: u(1) solid rgba($primary-color, 0.5);
    max-width: u(876);
}

.Search-list-item {
    border-top: u(1) solid rgba($primary-color, 0.5);
    padding: u($spacing-unit) 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.fonts-loaded .Search-title {
    font-family: $font-nav;
}

.Search-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: u(16);
}

.Search-content {
    width: 90%;
}

.Search-arrow {
    width: u(21);
    height: u(21);
    margin-left: u($spacing-unit--sm);

    .Icon {
        width: u(4);
    }
}
