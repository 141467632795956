.Nav--footer {

    .Nav-list {
        align-items: center;

        .Nav-item:first-child {
            margin-right: u($spacing-unit--sm);
        }
    }

    .Nav-link {
        color: rgba($black, 0.35);
        padding: u($spacing-unit--sm);
        transition: color $global-transition;

        &.is-active
        &:focus,
        &:hover,
        &:active {
            color: rgba($black, 0.5);
        }
    }
}

.Nav--footer-main,
.Nav--footer-extra {

    .Nav-list {
        display: block;
    }

    .Nav-link {
        color: $tertiary-color;
        padding: u($spacing-unit--xs) u($spacing-unit--sm);
        margin-left: u(-$spacing-unit--sm);
        transition: color $global-transition;

        &.is-active
        &:focus,
        &:hover,
        &:active {
            color: $white;
        }
    }
}
