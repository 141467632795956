/* Image */

// Fluid
img {
    @include image-rendering($image-rendering);
    max-width: 100%;
    height: auto;
}


// Figure
figure {

    &,
    > img {
        display: block;
    }

    img {
        min-height: 100%;
    }
}

// Picture
picture {
    display: block;
    position: relative;
    overflow: hidden;

    img {

        &::before {
            @include position(absolute, 0, 0, 0, 0);
            background-color: $image-color;
            display: block;
            content: '';
        }

        &::after {
            @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M13.4,9.7l1.2-1.2A5,5,0,0,0,16,5a4.69,4.69,0,0,0-1.5-3.5A4.69,4.69,0,0,0,11,0,4.69,4.69,0,0,0,7.5,1.5L6.3,2.6A1,1,0,0,0,6.3,4,1,1,0,0,0,7.7,4L8.9,2.8a3.09,3.09,0,0,1,4.2,0A3.1,3.1,0,0,1,14,5a2.72,2.72,0,0,1-.9,2.1L12,8.3a1,1,0,0,0,0,1.4,1,1,0,0,0,1.4,0ZM8.3,12,7.1,13.2a3.09,3.09,0,0,1-4.2,0A3.1,3.1,0,0,1,2,11a2.72,2.72,0,0,1,.9-2.1L4,7.7A1,1,0,0,0,4,6.3a1,1,0,0,0-1.4,0L1.5,7.5A4.69,4.69,0,0,0,0,11a4.69,4.69,0,0,0,1.5,3.5A4.69,4.69,0,0,0,5,16a4.69,4.69,0,0,0,3.5-1.5l1.2-1.2A1,1,0,1,0,8.3,12ZM9.4,5.2,5.2,9.4a1,1,0,0,0,0,1.4,1.08,1.08,0,0,0,.7.3,1.08,1.08,0,0,0,.7-.3l4.2-4.2A1,1,0,1,0,9.4,5.2ZM2.3,3.7A.91.91,0,0,0,3,4a.91.91,0,0,0,.7-.3,1,1,0,0,0,0-1.4l-2-2A1,1,0,0,0,.3.3a1,1,0,0,0,0,1.4Zm11.4,8.6a1,1,0,0,0-1.4,1.4l2,2a1,1,0,0,0,1.4,0,1,1,0,0,0,0-1.4Z" fill="#444"/></svg>');
            @include position(absolute, $top: 50%, $left: 50%);
            background-position: top center;
            background-repeat: no-repeat;
            background-size: u($spacing-unit / 1.5) auto;
            content: "Image could not be loaded:\A"attr(alt);
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: u($spacing-unit);
            transform: translate(-50%, -50%);
            font-size: 75%;
            line-height: 1.75;
            text-align: center;
            white-space: pre;

            html[lang="nl"] & {
                content: "Afbeelding kon niet worden geladen:\A"attr(alt);
            }

            html[lang="fr"] & {
                content: "L\'image n'a pas pu être chargée:\A"attr(alt);
            }
        }
    }

    &:empty {

        &::after {
            @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 64 64"><g transform="translate(0, 0)"><path d="M59,7H5a3,3,0,0,0-3,3V54a3,3,0,0,0,3,3H59a3,3,0,0,0,3-3V10A3,3,0,0,0,59,7ZM5,9H59a1,1,0,0,1,1,1V37.472L46.729,23.316a1,1,0,0,0-1.436-.023L27.734,40.853l3.349,6.73L15.673,33.26a1,1,0,0,0-1.38.033L4,43.586V10A1,1,0,0,1,5,9Z" fill="#444444"></path><circle data-color="color-2" cx="24" cy="21" r="6" fill="#444444"></circle></g></svg>');
            @include position(absolute, 0, 0, 0, 0);
            background-size: u($spacing-unit--lg);
            background-repeat: no-repeat;
            background-position: 50%;
            content: '';
            display: block;
        }
    }

    &[data-ratio] {

        > img {
            @include position(absolute);
            min-height: 100%;
            width: 100%;
        }
    }

    // Modifiers
    @each $ratio-name, $ratio-value in $RATIOS {

        @each $antecedent, $consequent in $ratio-value {

            @if (type-of($antecedent) != number) {
                @error "`#{$antecedent}` needs to be a number.";
            }

            @if (type-of($consequent) != number) {
                @error "`#{$consequent}` needs to be a number.";
            }

            &[data-ratio="#{$antecedent}:#{$consequent}"] {
                padding-bottom: ($consequent/$antecedent) * 100%;
            }
        }
    }
}