/**
 * Align: Medium
 */

@include mq($from: $viewport--md, $until: $viewport--lg - 1) {
    .u-md-alignBaseline {
        vertical-align: baseline !important;
    }

    .u-md-alignBottom {
        vertical-align: bottom !important;
    }

    .u-md-alignMiddle {
        vertical-align: middle !important;
    }

    .u-md-alignTop {
        vertical-align: top !important;
    }
}
