@charset "UTF-8";
/* Style */
/* Settings: Base */
/* Settings: Colors */
/* Colors: Palette */
/* Colors: Custom */
/* Colors: Theme */
/* Settings: Fonts */
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Light.woff") format("woff"), url("../fonts/Roboto-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/Roboto-Medium.woff") format("woff"), url("../fonts/Roboto-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Bebas Kai';
  src: url("../fonts/BebasKai.woff") format("woff"), url("../fonts/BebasKai.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Garamond';
  src: url("../fonts/GaramondBold.woff") format("woff"), url("../fonts/GaramondBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Garamond';
  src: url("../fonts/GaramondBoldItalic.woff") format("woff"), url("../fonts/GaramondBoldItalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Garamond';
  src: url("../fonts/GaramondRegular.woff") format("woff"), url("../fonts/GaramondRegular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

/* Elements: Heading */
/* Elements: HR */
/* Elements: Image */
/* Elements: Link */
/* Elements: List */
/* Elements: Paragraph */
/* Elements: Selection */
/* Objects: Alert */
/* Components: Button */
/* Object: Close */
/* Objects: Alert */
/* Objects: FieldGroup */
/* Objects: FlexEmbed */
/* Objects: Form */
/* Objects: Grid */
/* Objects: Nav */
/* Objects: Structure */
/* Objects: Table */
/* Objects: Toggle */
/* Components: Fields */
/* Components: Modal */
/* Components: DigitalPulse */
/* Functions: Throw */
/* Functions: Checks */
/* Functions: Truncate list */
/* Functions: Maps */
/* Functions: Maps */
/* Functions: Order index */
/* Functions: Units */
/* Functions: String */
/* Functions: SVG */
/* Mixin: Arrow */
/* Mixin: Background SVG */
/* Mixin: Caret */
/* Mixin: Context */
/* Mixin: Dimensions */
/* Mixin: Family */
/* Mixin: Font-responsive */
/* Mixin: Font-size */
/* Mixin: Font-smoothing */
/* Mixin: Grid */
/* Mixin: Hover */
/* Mixin: Image rendering */
/* Mixin: Last-children */
/* Mixin: Line-height crop */
/* Mixin: Maps */
/* Mixin: ParentNthStatus */
/* Mixin: ParentState */
/* Mixin: ParentStatus */
/* Mixin: Position */
/* Mixin: Maps */
/* Mixin: Transition */
/* Mixin: Truncate */
/* Reset */
*, *::before, *::after {
  box-sizing: inherit; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 62.5%;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal; }

body {
  font-size: 1.6em; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0; }

iframe {
  border: 0; }

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  display: block;
  width: 100%; }

option {
  font: inherit; }

textarea {
  resize: vertical; }

button {
  background: transparent;
  border: 0;
  padding: 0; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

abbr[title],
dfn[title] {
  cursor: help; }

u,
ins {
  text-decoration: underline; }

img {
  font-style: italic; }

::selection {
  text-shadow: none; }

[tabindex="-1"]:focus {
  outline: none !important; }

b,
strong {
  font-weight: 500; }

/* HR */
hr {
  margin-top: 2rem;
  margin-bottom: 1.9rem;
  color: #bdbdbd;
  border: 0;
  border-bottom: 1px solid; }

/* Image */
img {
  max-width: 100%;
  height: auto; }

figure,
figure > img {
  display: block; }

figure img {
  min-height: 100%; }

picture {
  display: block;
  position: relative;
  overflow: hidden; }
  picture img::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #eeeeee;
    display: block;
    content: ''; }
  picture img::after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20d%3D%22M13.4%2C9.7l1.2-1.2A5%2C5%2C0%2C0%2C0%2C16%2C5a4.69%2C4.69%2C0%2C0%2C0-1.5-3.5A4.69%2C4.69%2C0%2C0%2C0%2C11%2C0%2C4.69%2C4.69%2C0%2C0%2C0%2C7.5%2C1.5L6.3%2C2.6A1%2C1%2C0%2C0%2C0%2C6.3%2C4%2C1%2C1%2C0%2C0%2C0%2C7.7%2C4L8.9%2C2.8a3.09%2C3.09%2C0%2C0%2C1%2C4.2%2C0A3.1%2C3.1%2C0%2C0%2C1%2C14%2C5a2.72%2C2.72%2C0%2C0%2C1-.9%2C2.1L12%2C8.3a1%2C1%2C0%2C0%2C0%2C0%2C1.4%2C1%2C1%2C0%2C0%2C0%2C1.4%2C0ZM8.3%2C12%2C7.1%2C13.2a3.09%2C3.09%2C0%2C0%2C1-4.2%2C0A3.1%2C3.1%2C0%2C0%2C1%2C2%2C11a2.72%2C2.72%2C0%2C0%2C1%2C.9-2.1L4%2C7.7A1%2C1%2C0%2C0%2C0%2C4%2C6.3a1%2C1%2C0%2C0%2C0-1.4%2C0L1.5%2C7.5A4.69%2C4.69%2C0%2C0%2C0%2C0%2C11a4.69%2C4.69%2C0%2C0%2C0%2C1.5%2C3.5A4.69%2C4.69%2C0%2C0%2C0%2C5%2C16a4.69%2C4.69%2C0%2C0%2C0%2C3.5-1.5l1.2-1.2A1%2C1%2C0%2C1%2C0%2C8.3%2C12ZM9.4%2C5.2%2C5.2%2C9.4a1%2C1%2C0%2C0%2C0%2C0%2C1.4%2C1.08%2C1.08%2C0%2C0%2C0%2C.7.3%2C1.08%2C1.08%2C0%2C0%2C0%2C.7-.3l4.2-4.2A1%2C1%2C0%2C1%2C0%2C9.4%2C5.2ZM2.3%2C3.7A.91.91%2C0%2C0%2C0%2C3%2C4a.91.91%2C0%2C0%2C0%2C.7-.3%2C1%2C1%2C0%2C0%2C0%2C0-1.4l-2-2A1%2C1%2C0%2C0%2C0%2C.3.3a1%2C1%2C0%2C0%2C0%2C0%2C1.4Zm11.4%2C8.6a1%2C1%2C0%2C0%2C0-1.4%2C1.4l2%2C2a1%2C1%2C0%2C0%2C0%2C1.4%2C0%2C1%2C1%2C0%2C0%2C0%2C0-1.4Z%22%20fill%3D%22%23444%22%2F%3E%3C%2Fsvg%3E");
    position: absolute;
    top: 50%;
    left: 50%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 1.33333rem auto;
    content: "Image could not be loaded:\a" attr(alt);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    transform: translate(-50%, -50%);
    font-size: 75%;
    line-height: 1.75;
    text-align: center;
    white-space: pre; }
    html[lang="nl"] picture img::after {
      content: "Afbeelding kon niet worden geladen:\a" attr(alt); }
    html[lang="fr"] picture img::after {
      content: "L'image n'a pas pu être chargée:\a" attr(alt); }
  picture:empty::after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2264px%22%20height%3D%2264px%22%20viewBox%3D%220%200%2064%2064%22%3E%3Cg%20transform%3D%22translate(0%2C%200)%22%3E%3Cpath%20d%3D%22M59%2C7H5a3%2C3%2C0%2C0%2C0-3%2C3V54a3%2C3%2C0%2C0%2C0%2C3%2C3H59a3%2C3%2C0%2C0%2C0%2C3-3V10A3%2C3%2C0%2C0%2C0%2C59%2C7ZM5%2C9H59a1%2C1%2C0%2C0%2C1%2C1%2C1V37.472L46.729%2C23.316a1%2C1%2C0%2C0%2C0-1.436-.023L27.734%2C40.853l3.349%2C6.73L15.673%2C33.26a1%2C1%2C0%2C0%2C0-1.38.033L4%2C43.586V10A1%2C1%2C0%2C0%2C1%2C5%2C9Z%22%20fill%3D%22%23444444%22%3E%3C%2Fpath%3E%3Ccircle%20data-color%3D%22color-2%22%20cx%3D%2224%22%20cy%3D%2221%22%20r%3D%226%22%20fill%3D%22%23444444%22%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: 4rem;
    background-repeat: no-repeat;
    background-position: 50%;
    content: '';
    display: block; }
  picture[data-ratio] > img {
    position: absolute;
    min-height: 100%;
    width: 100%; }
  picture[data-ratio="1:1"] {
    padding-bottom: 100%; }
  picture[data-ratio="2:1"] {
    padding-bottom: 50%; }
  picture[data-ratio="3:2"] {
    padding-bottom: 66.66667%; }
  picture[data-ratio="4:3"] {
    padding-bottom: 75%; }
  picture[data-ratio="8:5"] {
    padding-bottom: 62.5%; }
  picture[data-ratio="16:9"] {
    padding-bottom: 56.25%; }

/* Link */
a {
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: #3f51b5;
  text-decoration: underline; }
  a:hover, a:focus, a:active {
    color: #3949ab;
    text-decoration: none; }

/* List */
ol,
ul {
  margin-bottom: 2rem;
  padding-left: 2rem; }
  ol li,
  ul li {
    margin-bottom: 0.2rem; }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem; }

ul {
  list-style-type: disc; }

ul ul {
  list-style-type: circle; }

ul ul ul {
  list-style-type: square; }

ol {
  list-style-type: decimal; }

ol ol {
  list-style-type: upper-alpha; }

ol ol ol {
  list-style-type: lower-roman; }

ol ol ol ol {
  list-style-type: lower-alpha; }

dt {
  font-weight: 700; }

ul li::marker,
ol li::marker {
  color: #BE9F56; }

ol li::marker {
  font-family: "Garamond", serif;
  font-weight: 700;
  font-size: 1.6rem; }

ol li {
  padding-left: 2rem;
  padding-bottom: 2rem; }

/* Selection */
::selection {
  color: #000000;
  background-color: #f1f075; }

/* Heading */
h1, .Alfa,
h2, .Bravo,
h3, .Charlie,
h4, .Delta,
h5, .Echo,
h6, .Foxtrot {
  font-family: serif; }
  .fonts-loaded h1, .fonts-loaded .Alfa, .fonts-loaded
  h2, .fonts-loaded .Bravo, .fonts-loaded
  h3, .fonts-loaded .Charlie, .fonts-loaded
  h4, .fonts-loaded .Delta, .fonts-loaded
  h5, .fonts-loaded .Echo, .fonts-loaded
  h6, .fonts-loaded .Foxtrot {
    font-family: "Garamond", serif; }
  h1 small, .Alfa small,
  h2 small, .Bravo small,
  h3 small, .Charlie small,
  h4 small, .Delta small,
  h5 small, .Echo small,
  h6 small, .Foxtrot small {
    font-weight: normal; }

h1, .Alfa,
h2, .Bravo,
h3, .Charlie {
  text-transform: uppercase;
  font-weight: 700; }

h4, .Delta,
h5, .Echo,
h6, .Foxtrot {
  color: #BE9F56; }

h1, .Alfa {
  margin-bottom: 1.5rem;
  font-size: 3.5rem;
  line-height: 1.2;
  line-height: 5rem; }
  @media screen and (min-width: 37.5em) {
    h1, .Alfa {
      margin-bottom: 1.5rem;
      font-size: 6rem;
      line-height: 1.1;
      line-height: 7rem; } }

h2, .Bravo {
  margin-bottom: 1.5rem;
  font-size: 4.5rem;
  line-height: 1.2;
  line-height: 5rem; }
  @media screen and (max-width: 48em) {
    h2, .Bravo {
      font-size: 4rem; } }

h3, .Charlie {
  margin-bottom: 1.5rem;
  font-size: 3.5rem;
  line-height: 1.2; }

h4, .Delta {
  margin-bottom: 1.5rem;
  font-size: 2.2rem;
  text-transform: uppercase;
  margin-bottom: 3rem;
  font-weight: 700; }

h5, .Echo {
  margin-bottom: 1.5rem;
  font-size: 3rem;
  line-height: 1.2;
  line-height: 3.5rem;
  text-transform: none;
  font-weight: 400; }

h6, .Foxtrot {
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
  text-transform: uppercase; }

.Giga {
  margin-bottom: 1.5rem;
  font-size: 9.6rem;
  line-height: 1.1; }

.Mega {
  margin-bottom: 1.5rem;
  font-size: 7.2rem;
  line-height: 1.1; }

.Kilo {
  margin-bottom: 1.5rem;
  font-size: 4.8rem;
  line-height: 1.2; }

.Milli {
  margin-bottom: 1.5rem;
  font-size: 1.2rem; }

.Micro {
  margin-bottom: 1.5rem;
  font-size: 1rem; }

/* Paragraph */
p {
  margin-bottom: 2rem; }
  p a {
    color: #BE9F56; }
  p a:hover {
    color: #BE9F56;
    text-decoration: underline; }

.Lead {
  font-size: 1.8rem; }

/* Main */
html {
  background-color: #ffffff;
  color: #000000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  height: 100%; }
  html.fonts-loaded {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 300; }
  html.hide-scroll {
    overflow: hidden; }

body {
  font-size: 1.5rem;
  line-height: 2.5rem;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.85);
  height: 100%; }

em {
  font-style: normal;
  color: #BE9F56; }

.Main-content-inner.Contact {
  margin: 14rem 0 10rem 0; }

@media screen and (max-width: 64em) {
  .Main-content-inner {
    margin: 12rem 0; } }

/* Alert */
.Alert {
  border: 1px solid;
  border-radius: 2px;
  margin-bottom: 2rem;
  padding: 1rem 1.33333rem 1rem 1.33333rem;
  position: relative; }
  .Alert.is-dismissible {
    padding-right: 5.06667rem; }
    .Alert.is-dismissible .Close {
      position: absolute;
      top: 0;
      right: 0;
      color: inherit;
      padding: 1rem 1.33333rem 1rem 1.33333rem; }
  .Alert p,
  .Alert ul {
    margin-bottom: 0; }
    .Alert p + p,
    .Alert p + ul,
    .Alert ul + p,
    .Alert ul + ul {
      margin-top: 2rem; }

/* Object: Button */
.Button {
  transition-property: background-color, border-color, box-shadow, color;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  -webkit-appearance: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: inherit;
  line-height: 19px;
  padding: 0.9375em 1.25em 0.9375em 1.25em;
  text-align: left;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 0.1em; }
  .Button::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  .Button:hover {
    color: inherit;
    text-decoration: none; }
  .Button:focus, .Button.is-focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25); }
  .Button:disabled, .Button.is-disabled {
    box-shadow: none;
    cursor: default;
    opacity: 0.6;
    pointer-events: none; }
  .Button .Icon {
    flex-shrink: initial;
    width: 1rem;
    max-height: 1.7rem;
    margin-right: 1rem; }

.ButtonGroup {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .ButtonGroup > .Button {
    position: relative;
    flex: 1 1 auto; }
    .ButtonGroup > .Button:hover, .ButtonGroup > .Button:focus, .ButtonGroup > .Button:active, .ButtonGroup > .Button.active {
      z-index: 1; }
  .ButtonGroup:not(.ButtonGroup--vertical) > .Button:not(:first-child),
  .ButtonGroup:not(.ButtonGroup--vertical) > .ButtonGroup:not(:first-child) {
    margin-left: -1px; }
  .ButtonGroup:not(.ButtonGroup--vertical) > .Button:not(:last-child):not(.dropdown-toggle),
  .ButtonGroup:not(.ButtonGroup--vertical) > .ButtonGroup:not(:last-child) > .Button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .ButtonGroup:not(.ButtonGroup--vertical) > .Button:not(:first-child),
  .ButtonGroup:not(.ButtonGroup--vertical) > .ButtonGroup:not(:first-child) > .Button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .ButtonGroup--vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
    .ButtonGroup--vertical > .Button,
    .ButtonGroup--vertical > .ButtonGroup {
      width: 100%; }
    .ButtonGroup--vertical > .Button:not(:first-child),
    .ButtonGroup--vertical > .ButtonGroup:not(:first-child) {
      margin-top: -1px; }
    .ButtonGroup--vertical > .Button:not(:last-child):not(.dropdown-toggle),
    .ButtonGroup--vertical > .ButtonGroup:not(:last-child) > .Button {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .ButtonGroup--vertical > .Button:not(:first-child),
    .ButtonGroup--vertical > .ButtonGroup:not(:first-child) > .Button {
      border-top-right-radius: 0;
      border-top-left-radius: 0; }

/* Object: Containers */
.Container {
  margin-right: auto;
  margin-left: auto;
  max-width: 134rem;
  padding-right: 2rem;
  padding-left: 2rem;
  width: 100%; }
  @media screen and (min-width: 48em) {
    .Container {
      max-width: 138rem;
      padding-right: 4rem;
      padding-left: 4rem; } }
  .Container--sm {
    max-width: 80.8rem; }
    @media screen and (min-width: 48em) {
      .Container--sm {
        max-width: 84.8rem; } }
  .Container--md {
    max-width: 134rem; }
    @media screen and (min-width: 48em) {
      .Container--md {
        max-width: 138rem; } }
  .Container--lg {
    max-width: 154rem; }
    @media screen and (min-width: 48em) {
      .Container--lg {
        max-width: 158rem; } }

/* Object: Close */
.Close {
  font-size: 2.4rem;
  line-height: 1.2;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5; }
  .Close:hover {
    color: #000000;
    text-decoration: none; }
  .Close:hover, .Close:focus {
    opacity: .75; }
  .Close:disabled, .Close.is-disabled {
    pointer-events: none; }

button.Close {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  padding: 0; }

/* Objects: FieldGroup */
.FieldGroup {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .FieldGroup > .Form-field {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .FieldGroup > .Form-field:focus {
      z-index: 3; }
    .FieldGroup > .Form-field + .Form-field {
      margin-left: -1px; }
    .FieldGroup > .Form-field:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .FieldGroup > .Form-field:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .FieldGroup-prepend, .FieldGroup-append {
    display: flex; }
    .FieldGroup-prepend .Button, .FieldGroup-append .Button {
      position: relative;
      z-index: 2; }
      .FieldGroup-prepend .Button:focus, .FieldGroup-append .Button:focus {
        z-index: 3; }
    .FieldGroup-prepend .Button + .Button,
    .FieldGroup-prepend .Button + .FieldGroup-text,
    .FieldGroup-prepend .FieldGroup-text + .FieldGroup-text,
    .FieldGroup-prepend .FieldGroup-text + .Button, .FieldGroup-append .Button + .Button,
    .FieldGroup-append .Button + .FieldGroup-text,
    .FieldGroup-append .FieldGroup-text + .FieldGroup-text,
    .FieldGroup-append .FieldGroup-text + .Button {
      margin-left: -1px; }
  .FieldGroup-prepend {
    margin-right: -1px; }
  .FieldGroup-append {
    margin-left: -1px; }
  .FieldGroup > .FieldGroup-prepend > .Button,
  .FieldGroup > .FieldGroup-prepend > .FieldGroup-text,
  .FieldGroup > .FieldGroup-append:not(:last-child) > .Button,
  .FieldGroup > .FieldGroup-append:not(:last-child) > .FieldGroup-text,
  .FieldGroup > .FieldGroup-append:last-child > .Button:not(:last-child):not(.dropdown-toggle),
  .FieldGroup > .FieldGroup-append:last-child > .FieldGroup-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .FieldGroup > .FieldGroup-append > .Button,
  .FieldGroup > .FieldGroup-append > .FieldGroup-text,
  .FieldGroup > .FieldGroup-prepend:not(:first-child) > .Button,
  .FieldGroup > .FieldGroup-prepend:not(:first-child) > .FieldGroup-text,
  .FieldGroup > .FieldGroup-prepend:first-child > .Button:not(:first-child),
  .FieldGroup > .FieldGroup-prepend:first-child > .FieldGroup-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .FieldGroup-text {
    font-size: 1.6rem;
    align-items: center;
    background-color: #eeeeee;
    border: 1px solid #BE9F56;
    border-radius: 0rem;
    color: inherit;
    display: flex;
    font-weight: 400;
    line-height: 2.4rem;
    margin-bottom: 0;
    padding: 1.25em 1.25em 1.25em 1.25em;
    text-align: center;
    white-space: nowrap; }

/* FlexEmbed */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }
  .FlexEmbed::before {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%; }

.FlexEmbed-content,
.FlexEmbed-content > iframe,
.FlexEmbed-content > object,
.FlexEmbed-content > embed,
.FlexEmbed-content > video {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

img.FlexEmbed-content {
  object-fit: cover; }

.FlexEmbed--1by1:before {
  padding-bottom: 100%; }

.FlexEmbed--2by1:before {
  padding-bottom: 50%; }

.FlexEmbed--3by2:before {
  padding-bottom: 66.66667%; }

.FlexEmbed--4by3:before {
  padding-bottom: 75%; }

.FlexEmbed--8by5:before {
  padding-bottom: 62.5%; }

.FlexEmbed--16by9:before {
  padding-bottom: 56.25%; }

/* Form */
.Form-item:not(.Form-item--action) {
  margin-bottom: 2rem; }

.Form-label {
  display: inline-block;
  margin-bottom: 0.5rem; }
  .Form-item:not(.is-required) .Form-label[data-optional]::after {
    content: attr(data-optional);
    font-size: 65%;
    font-style: italic;
    margin-left: 0.5rem;
    text-transform: lowercase; }
  .Form-item.has-error .Form-label {
    color: #f44336; }
  .Form-item.is-success .Form-label {
    color: #4caf50; }

.Form-help {
  margin-top: 0.5rem;
  color: #bdbdbd;
  font-size: 1.2rem; }
  .Form-help:first-child {
    margin-top: 0;
    margin-bottom: 1rem; }
  .Form-help :last-child {
    margin-bottom: 0; }
  .Form-help + .Form-help {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 0.1rem solid #e0e0e0; }

.Form-item.has-error .Form-help--validation {
  color: #f44336; }

.Form-item.is-success .Form-help--validation {
  color: #4caf50; }

.Form-controls {
  position: relative; }

@media screen and (min-width: 37.5em) {
  .Form--horizontal .Form-item {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start; }
  .Form--horizontal .Form-label {
    margin-top: 0.5rem;
    margin-bottom: 0;
    padding-right: 2rem;
    width: 33.33333%; }
  .Form--horizontal .Form-controls {
    width: 66.66667%; }
  .Form--horizontal .Form-item--action {
    padding-left: 33.33333%; } }

/* Grid */
/* Grid */
/**
     * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
     * widths and offsets should be applied to child grid cells.
     */
.Grid {
  box-sizing: border-box;
  display: flex;
  /* 1 */
  flex-flow: row wrap;
  margin: 0;
  /* 1 */
  padding: 0;
  /* 1 */ }

/**
        * No explicit width by default. Rely on combining `Grid-cell` with a dimension
        * utility or a component class that extends 'Grid'.
        *
        * 1. Set flex items to full width by default
        * 2. Fix issue where elements with overflow extend past the
        *      `Grid-cell` container - https://git.io/vw5oF
        */
.Grid-cell {
  box-sizing: inherit;
  flex-basis: 100%;
  /* 1 */
  min-width: 0;
  /* 2 */ }

/**
        * Modifiers
        */
.Grid--alignCenter {
  justify-content: center; }

.Grid--alignRight {
  justify-content: flex-end; }

.Grid--alignMiddle {
  align-items: center; }

.Grid--alignBottom {
  align-items: flex-end; }

.Grid--fill > .Grid-cell {
  flex: 1 1 0%;
  /* 1 */ }

.Grid--fit > .Grid-cell {
  flex-basis: auto; }

.Grid--equalHeight > .Grid-cell {
  display: flex; }

.Grid--stretch {
  align-items: stretch; }

.Grid--withExtraSmallGutter {
  margin-left: -0.5rem; }
  .Grid--withExtraSmallGutter > .Grid-cell {
    padding-left: 0.5rem; }

.Grid--withSmallGutter {
  margin-left: -1rem; }
  .Grid--withSmallGutter > .Grid-cell {
    padding-left: 1rem; }

.Grid--withGutter {
  margin-left: -2rem; }
  .Grid--withGutter > .Grid-cell {
    padding-left: 2rem; }

.Grid--withLargeGutter {
  margin-left: -4rem; }
  .Grid--withLargeGutter > .Grid-cell {
    padding-left: 4rem; }

.Grid--withExtraLargeGutter {
  margin-left: -8rem; }
  .Grid--withExtraLargeGutter > .Grid-cell {
    padding-left: 8rem; }

/* Icon */
.Icon {
  display: block;
  overflow: visible; }
  .Button .Icon,
  .Link .Icon {
    flex-shrink: 0; }

/* Nav */
.Nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 0 1 auto; }

.Nav-item {
  margin: 0; }

.Nav-link {
  user-select: none;
  display: block; }
  .Nav-link, .Nav-link:active, .Nav-link:focus, .Nav-link:hover {
    text-decoration: none; }

.Nav--stacked .Nav-list {
  flex-direction: column;
  flex-wrap: nowrap; }

.Nav--fit:not(.Nav--stacked) .Nav-item {
  flex: 1 1 auto; }

.Nav--alignCenter {
  text-align: center; }
  .Nav--alignCenter:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
    justify-content: center; }

.Nav--alignRight {
  text-align: right; }
  .Nav--alignRight:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
    justify-content: flex-end; }

/* Structure */
.Wrapper {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header" "main" "footer";
  min-height: 100%;
  overflow-x: hidden; }

.Header {
  position: relative;
  z-index: 3;
  grid-area: header; }

.Main {
  position: relative;
  z-index: 2;
  grid-area: main; }
  .Main-content > .Container {
    padding-top: 2rem;
    padding-bottom: 2rem; }

.Footer {
  position: relative;
  z-index: 1;
  grid-area: footer; }

/* Table */
.Table {
  background-color: transparent;
  border: 0.1rem solid #e0e0e0;
  table-layout: fixed;
  text-align: left;
  width: 100%; }
  .Table th,
  .Table td {
    vertical-align: middle;
    padding: 0.33333rem 0.66667rem 0.33333rem 0.66667rem; }
  .Table thead {
    background-color: #f5f5f5;
    border-bottom: 2px solid #e0e0e0; }
  .Table tbody tr + tr {
    border-top: 0.1rem solid #e0e0e0; }
  .Table tfoot {
    background-color: #f5f5f5;
    border-top: 2px solid #e0e0e0; }
  @media screen and (max-width: 47.9375em) {
    .Table.is-responsive {
      border-width: 0; }
      .Table.is-responsive,
      .Table.is-responsive tbody,
      .Table.is-responsive tfoot,
      .Table.is-responsive tr {
        display: block; }
      .Table.is-responsive thead {
        display: none; }
      .Table.is-responsive tbody tr {
        border: 0.1rem solid #e0e0e0;
        border-bottom-width: 0; }
      .Table.is-responsive tfoot {
        border: 0.1rem solid #e0e0e0;
        border-top-width: 2px; }
      .Table.is-responsive .Table-cell {
        display: inline-block;
        vertical-align: top;
        width: 100%; }
        .Table.is-responsive .Table-cell[data-table-label]::before {
          content: attr(data-table-label);
          display: block;
          margin: -1rem -1rem 1rem;
          padding: 1rem;
          background-color: #fafafa;
          border-top: 0.1rem solid #e0e0e0;
          border-bottom: 0.1rem solid #e0e0e0; } }

.Table--striped tbody tr:nth-child(even) {
  background-color: #fafafa; }

@media screen and (max-width: 47.9375em) {
  .Table--striped.is-responsive tbody tr:nth-child(even) {
    background-color: transparent; } }

.Toggle {
  height: 4rem;
  width: 4rem;
  position: relative;
  cursor: pointer;
  transform: rotate(0deg);
  background: #BE9F56;
  color: #ffffff;
  border-radius: 50%; }
  .Toggle-item {
    position: absolute;
    transition-property: top, transform, opacity;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    width: 1.6rem;
    height: 0.1rem;
    background-color: currentColor;
    border-radius: 0px;
    display: block;
    transition: transform 0.44s cubic-bezier(0.23, 1, 0.32, 1);
    transform: rotate(0deg);
    left: 0;
    right: 0;
    margin: auto; }
    .Toggle-item:nth-child(1) {
      top: 1.95rem;
      transform: translate(0, -0.5rem) rotate(0); }
      .show-nav .Toggle-item:nth-child(1) {
        top: 1.95rem;
        transform: translate(0, 0) rotate(135deg); }
    .Toggle-item:nth-child(2) {
      top: 1.95rem; }
      .show-nav .Toggle-item:nth-child(2) {
        opacity: 0; }
    .Toggle-item:nth-child(3) {
      top: 1.95rem;
      width: 0.8rem;
      transform: translate(0.4rem, 0.5rem) rotate(0); }
      .show-nav .Toggle-item:nth-child(3) {
        transform: translate(0, 0) rotate(-135deg) scaleX(2); }
  .Toggle:hover .Toggle-item:nth-child(3) {
    transform: translate(0, 0.5rem) rotate(0) scaleX(2); }

.show-nav .Toggle:hover .Toggle-item:nth-child(3) {
  transform: translate(0, 0) rotate(-135deg) scaleX(2); }

.List--none {
  padding: 0;
  margin: 0;
  list-style: none; }

.List-description {
  display: flex; }
  .List-description-label {
    font-weight: 300;
    flex-basis: 12rem; }

.Link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0;
  transition: color 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
  .Link--primary {
    color: #BE9F56;
    transition: opacity 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
    .Link--primary:hover {
      opacity: 0.7; }
  .Link--tertiary {
    color: rgba(255, 255, 255, 0.65); }
    .Link--tertiary:hover {
      color: #ffffff; }
  .Link--small {
    font-size: 1.2rem;
    line-height: 2.5rem;
    letter-spacing: 0.5px;
    font-weight: 300; }
  .Link--none {
    text-decoration: none;
    color: inherit; }
    .Link--none:hover {
      color: #BE9F56; }
  .Link--start {
    align-items: flex-start; }
    .Link--start .Icon {
      transform: translateY(25%); }
  .Link .Icon {
    margin-right: 1rem; }

/* Alert */
.Alert--primary {
  color: #63532d;
  background-color: #f2ecdd;
  border-color: #ede4d0; }
  .Alert--primary hr {
    border-bottom-color: #e6d9be; }
  .Alert--primary .Alert-link {
    color: #40361d; }

.Alert--secondary {
  color: gray;
  background-color: #fdfdfd;
  border-color: #fdfdfd; }
  .Alert--secondary hr {
    border-bottom-color: #f0f0f0; }
  .Alert--secondary .Alert-link {
    color: #676767; }

.Alert--tertiary {
  color: rgba(87, 87, 87, 0.818);
  background-color: rgba(255, 255, 255, 0.93);
  border-color: rgba(255, 255, 255, 0.902); }
  .Alert--tertiary hr {
    border-bottom-color: rgba(242, 242, 242, 0.902); }
  .Alert--tertiary .Alert-link {
    color: rgba(62, 62, 62, 0.818); }

.Alert--success {
  color: #285b2a;
  background-color: #dbefdc;
  border-color: #cde9ce; }
  .Alert--success hr {
    border-bottom-color: #bbe1bd; }
  .Alert--success .Alert-link {
    color: #18381a; }

.Alert--info {
  color: #114e7e;
  background-color: #d3eafd;
  border-color: #c1e2fc; }
  .Alert--info hr {
    border-bottom-color: #a9d7fb; }
  .Alert--info .Alert-link {
    color: #0b3251; }

.Alert--warning {
  color: #854f00;
  background-color: #ffeacc;
  border-color: #ffe2b8; }
  .Alert--warning hr {
    border-bottom-color: #ffd89f; }
  .Alert--warning .Alert-link {
    color: #523100; }

.Alert--danger {
  color: #7f231c;
  background-color: #fdd9d7;
  border-color: #fccac7; }
  .Alert--danger hr {
    border-bottom-color: #fbb3af; }
  .Alert--danger .Alert-link {
    color: #551713; }

.Alert-title {
  color: inherit; }

.Alert-link {
  font-weight: 700; }

/* Button */
.Button--primary {
  background-color: #BE9F56;
  border-color: #BE9F56;
  color: #ffffff; }
  .Button--primary:active, .Button--primary.is-active {
    background-color: #a3853f;
    border-color: #997d3b;
    color: #ffffff; }
  .Button--primary:focus, .Button--primary.is-focus {
    background-color: #BE9F56;
    border-color: #BE9F56;
    box-shadow: 0 0 0 0.2rem rgba(200, 173, 111, 0.5);
    color: #ffffff; }
  .Button--primary:hover, .Button--primary.is-hover {
    background-color: #ac8c42;
    border-color: #a3853f;
    color: #ffffff; }
  .Button--primary.Button--outline {
    background-color: transparent;
    color: #BE9F56;
    border: 0.1rem solid #BE9F56; }
    .Button--primary.Button--outline:active, .Button--primary.Button--outline:hover, .Button--primary.Button--outline.is-active, .Button--primary.Button--outline.is-hover {
      background-color: #BE9F56;
      color: #ffffff; }
  .Button--primary:disabled, .Button--primary.is-disabled {
    background-color: #BE9F56;
    border-color: #BE9F56;
    color: #ffffff; }

.Button--secondary {
  background-color: #F7F7F7;
  border-color: #F7F7F7;
  color: rgba(0, 0, 0, 0.7); }
  .Button--secondary:active, .Button--secondary.is-active {
    background-color: #dedede;
    border-color: #d7d7d7;
    color: rgba(0, 0, 0, 0.7); }
  .Button--secondary:focus, .Button--secondary.is-focus {
    background-color: #F7F7F7;
    border-color: #F7F7F7;
    box-shadow: 0 0 0 0.2rem rgba(226, 226, 226, 0.5);
    color: rgba(0, 0, 0, 0.7); }
  .Button--secondary:hover, .Button--secondary.is-hover {
    background-color: #e4e4e4;
    border-color: #dedede;
    color: rgba(0, 0, 0, 0.7); }
  .Button--secondary.Button--outline {
    background-color: transparent;
    color: #F7F7F7;
    border: 0.1rem solid #F7F7F7; }
    .Button--secondary.Button--outline:active, .Button--secondary.Button--outline:hover, .Button--secondary.Button--outline.is-active, .Button--secondary.Button--outline.is-hover {
      background-color: #F7F7F7;
      color: rgba(0, 0, 0, 0.7); }
  .Button--secondary:disabled, .Button--secondary.is-disabled {
    background-color: #F7F7F7;
    border-color: #F7F7F7;
    color: rgba(0, 0, 0, 0.7); }

.Button--tertiary {
  background-color: rgba(255, 255, 255, 0.65);
  border-color: rgba(255, 255, 255, 0.65);
  color: rgba(0, 0, 0, 0.7); }
  .Button--tertiary:active, .Button--tertiary.is-active {
    background-color: rgba(230, 230, 230, 0.65);
    border-color: rgba(223, 223, 223, 0.65);
    color: rgba(0, 0, 0, 0.7); }
  .Button--tertiary:focus, .Button--tertiary.is-focus {
    background-color: rgba(255, 255, 255, 0.65);
    border-color: rgba(255, 255, 255, 0.65);
    box-shadow: 0 0 0 0.2rem rgba(213, 213, 213, 0.5);
    color: rgba(0, 0, 0, 0.7); }
  .Button--tertiary:hover, .Button--tertiary.is-hover {
    background-color: rgba(236, 236, 236, 0.65);
    border-color: rgba(230, 230, 230, 0.65);
    color: rgba(0, 0, 0, 0.7); }
  .Button--tertiary.Button--outline {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.65);
    border: 0.1rem solid rgba(255, 255, 255, 0.65); }
    .Button--tertiary.Button--outline:active, .Button--tertiary.Button--outline:hover, .Button--tertiary.Button--outline.is-active, .Button--tertiary.Button--outline.is-hover {
      background-color: rgba(255, 255, 255, 0.65);
      color: rgba(0, 0, 0, 0.7); }
  .Button--tertiary:disabled, .Button--tertiary.is-disabled {
    background-color: rgba(255, 255, 255, 0.65);
    border-color: rgba(255, 255, 255, 0.65);
    color: rgba(0, 0, 0, 0.7); }

.Button--success {
  background-color: #4caf50;
  border-color: #4caf50;
  color: #ffffff; }
  .Button--success:active, .Button--success.is-active {
    background-color: #3d8b40;
    border-color: #39833c;
    color: #ffffff; }
  .Button--success:focus, .Button--success.is-focus {
    background-color: #4caf50;
    border-color: #4caf50;
    box-shadow: 0 0 0 0.2rem rgba(103, 187, 106, 0.5);
    color: #ffffff; }
  .Button--success:hover, .Button--success.is-hover {
    background-color: #409444;
    border-color: #3d8b40;
    color: #ffffff; }
  .Button--success.Button--outline {
    background-color: transparent;
    color: #4caf50;
    border: 0.1rem solid #4caf50; }
    .Button--success.Button--outline:active, .Button--success.Button--outline:hover, .Button--success.Button--outline.is-active, .Button--success.Button--outline.is-hover {
      background-color: #4caf50;
      color: #ffffff; }
  .Button--success:disabled, .Button--success.is-disabled {
    background-color: #4caf50;
    border-color: #4caf50;
    color: #ffffff; }

.Button--info {
  background-color: #2196f3;
  border-color: #2196f3;
  color: #ffffff; }
  .Button--info:active, .Button--info.is-active {
    background-color: #0c7cd5;
    border-color: #0b75c9;
    color: #ffffff; }
  .Button--info:focus, .Button--info.is-focus {
    background-color: #2196f3;
    border-color: #2196f3;
    box-shadow: 0 0 0 0.2rem rgba(66, 166, 245, 0.5);
    color: #ffffff; }
  .Button--info:hover, .Button--info.is-hover {
    background-color: #0c83e2;
    border-color: #0c7cd5;
    color: #ffffff; }
  .Button--info.Button--outline {
    background-color: transparent;
    color: #2196f3;
    border: 0.1rem solid #2196f3; }
    .Button--info.Button--outline:active, .Button--info.Button--outline:hover, .Button--info.Button--outline.is-active, .Button--info.Button--outline.is-hover {
      background-color: #2196f3;
      color: #ffffff; }
  .Button--info:disabled, .Button--info.is-disabled {
    background-color: #2196f3;
    border-color: #2196f3;
    color: #ffffff; }

.Button--warning {
  background-color: #ff9800;
  border-color: #ff9800;
  color: #ffffff; }
  .Button--warning:active, .Button--warning.is-active {
    background-color: #cc7a00;
    border-color: #bf7200;
    color: #ffffff; }
  .Button--warning:focus, .Button--warning.is-focus {
    background-color: #ff9800;
    border-color: #ff9800;
    box-shadow: 0 0 0 0.2rem rgba(255, 167, 38, 0.5);
    color: #ffffff; }
  .Button--warning:hover, .Button--warning.is-hover {
    background-color: #d98100;
    border-color: #cc7a00;
    color: #ffffff; }
  .Button--warning.Button--outline {
    background-color: transparent;
    color: #ff9800;
    border: 0.1rem solid #ff9800; }
    .Button--warning.Button--outline:active, .Button--warning.Button--outline:hover, .Button--warning.Button--outline.is-active, .Button--warning.Button--outline.is-hover {
      background-color: #ff9800;
      color: #ffffff; }
  .Button--warning:disabled, .Button--warning.is-disabled {
    background-color: #ff9800;
    border-color: #ff9800;
    color: #ffffff; }

.Button--danger {
  background-color: #f44336;
  border-color: #f44336;
  color: #ffffff; }
  .Button--danger:active, .Button--danger.is-active {
    background-color: #ea1c0d;
    border-color: #de1b0c;
    color: #ffffff; }
  .Button--danger:focus, .Button--danger.is-focus {
    background-color: #f44336;
    border-color: #f44336;
    box-shadow: 0 0 0 0.2rem rgba(246, 95, 84, 0.5);
    color: #ffffff; }
  .Button--danger:hover, .Button--danger.is-hover {
    background-color: #f22112;
    border-color: #ea1c0d;
    color: #ffffff; }
  .Button--danger.Button--outline {
    background-color: transparent;
    color: #f44336;
    border: 0.1rem solid #f44336; }
    .Button--danger.Button--outline:active, .Button--danger.Button--outline:hover, .Button--danger.Button--outline.is-active, .Button--danger.Button--outline.is-hover {
      background-color: #f44336;
      color: #ffffff; }
  .Button--danger:disabled, .Button--danger.is-disabled {
    background-color: #f44336;
    border-color: #f44336;
    color: #ffffff; }

.Button--white {
  background-color: #ffffff;
  color: #BE9F56; }
  .Button--white:active, .Button--white:hover, .Button--white.is-active, .Button--white.is-hover {
    background-color: rgba(255, 255, 255, 0.65);
    color: #BE9F56; }

.Button--link {
  color: #3f51b5;
  font-weight: inherit;
  text-decoration: underline; }
  .Button--link:hover, .Button--link.is-focus {
    color: #3949ab;
    text-decoration: none; }
  .Button--link:focus, .Button--link.is-focus {
    box-shadow: none;
    text-decoration: none; }

.Button--large {
  font-size: 2rem;
  padding: 1.25em 1.66667em 1.25em 1.66667em; }

.Button--small {
  font-size: 1.4rem;
  padding: 0.70312em 0.9375em 0.70312em 0.9375em; }

.Button--full {
  display: block;
  width: 100%; }
  .Button--full + .Button--full {
    margin-top: 1rem; }

.Button--circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  color: #BE9F56;
  border: 0.1rem solid #BE9F56;
  transition: background 0.44s cubic-bezier(0.23, 1, 0.32, 1), color 0.44s cubic-bezier(0.23, 1, 0.32, 1), transform 0.44s cubic-bezier(0.23, 1, 0.32, 1);
  padding: 0; }
  .Button--circle:hover, .Button--circle.is-active {
    background: #BE9F56;
    color: white; }
  .Button--circle .Icon {
    width: 0.6rem;
    margin: 0; }

.Button--circle-reversed {
  transform: scale(-1, 1);
  margin-right: 1rem; }

.Modal {
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 500; }
  .Modal, .Modal:before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .Modal::before {
    background-color: rgba(0, 0, 0, 0.85);
    content: '';
    z-index: -1; }
  .Modal:not(.is-active) {
    opacity: 0;
    visibility: hidden; }
  .Modal-inner {
    display: flex;
    align-items: center;
    min-height: 100%; }
    @media screen and (max-width: 48em) {
      .Modal-inner {
        padding: 0 20; } }
    .Modal-inner-body {
      background-color: #ffffff;
      margin: 2rem 2rem 2rem 2rem;
      width: 100%;
      position: relative;
      padding: 6rem; }
      @media screen and (max-width: 48em) {
        .Modal-inner-body {
          margin: 0 !important; } }
      @media screen and (min-width: 37.5em) {
        .Modal-inner-body {
          max-width: 54rem;
          width: 100%; } }
      @media screen and (min-width: 48em) {
        .Modal-inner-body {
          margin: 4rem 8rem; } }
      @media screen and (min-width: 33.75em) {
        .Modal-inner-body {
          margin: 4rem auto; } }
  .Modal-close {
    cursor: pointer;
    line-height: 1;
    margin-left: auto;
    margin-right: 0;
    text-indent: -1000px;
    overflow: hidden;
    position: absolute;
    top: 1rem;
    right: 1rem;
    transform: rotate(45deg);
    width: 2.5rem;
    height: 2.5rem; }
    .Modal-close:before, .Modal-close:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background: #BE9F56;
      transition: transform 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
    .Modal-close:after {
      transform: rotate(90deg); }
    @media screen and (min-width: 48em) {
      .Modal-close {
        top: 0;
        right: 0;
        transform: translate(150%, -20%) rotate(45deg); }
        .Modal-close:before, .Modal-close:after {
          background: #ffffff; } }
    .Modal-close:hover:before {
      transform: rotate(-45deg); }
    .Modal-close:hover:after {
      transform: rotate(135deg); }
    html:not(.is-tabbing) .Modal-close:focus {
      outline: 0; }
  .Modal .Form-item--input {
    margin-bottom: 3rem; }

.Modal-video .Modal-inner-body {
  background: none;
  padding: 0;
  margin: 0 auto;
  max-width: 130rem; }

@media screen and (max-width: 48em) {
  .Modal-video .Modal-close {
    transform: translate(1rem, -150%) rotate(45deg); }
    .Modal-video .Modal-close:before, .Modal-video .Modal-close:after {
      background: #ffffff; } }

.Modal-product .Modal-inner-body {
  margin: 0 auto;
  padding: 0;
  width: auto;
  height: auto;
  max-height: 60%; }

.Modal-legal {
  display: none; }
  .Modal-legal h2 {
    color: #BE9F56;
    line-height: 5rem; }

/* Navigation */
.Navigation-toggle {
  display: block;
  position: relative;
  z-index: 5; }

.Navigation-body {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  left: auto;
  width: 100%;
  min-height: 100vh;
  background-color: #000000;
  padding: 6.5rem 4rem;
  transform: translateX(0);
  transition: transform 0.44s cubic-bezier(0.23, 1, 0.32, 1), visibility 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
  @media screen and (min-width: 48em) {
    .Navigation-body {
      width: 34rem;
      background-color: rgba(0, 0, 0, 0.85); } }
  @media screen and (min-width: 81.25em) {
    .Navigation-body {
      width: calc(34rem + ((100vw - 130rem) / 2));
      padding-right: calc(((100vw - 138rem) / 2) - (4rem + 2rem)); } }
  .Navigation-body--inner {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    height: calc(100% + 1px); }
  html:not(.show-nav) .Navigation-body {
    transform: translateX(100%);
    visibility: hidden; }

/* Types */
/* Main */
.Nav--main .Nav-link {
  position: relative;
  color: #BE9F56;
  padding: 2rem 1rem; }
  @media screen and (min-width: 64em) {
    .Nav--main .Nav-link {
      padding: 2rem; } }
  .Nav--main .Nav-link:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0.7rem;
    height: 0.2rem;
    background: #BE9F56;
    transform: scaleX(0);
    transition: transform 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
  .Nav--main .Nav-link.is-active, .Nav--main .Nav-link:active, .Nav--main .Nav-link:focus, .Nav--main .Nav-link:hover {
    color: #ffffff; }
    .Nav--main .Nav-link.is-active:before, .Nav--main .Nav-link:active:before, .Nav--main .Nav-link:focus:before, .Nav--main .Nav-link:hover:before {
      transform: scaleX(1); }
  .Nav--main .Nav-link.is-webshop {
    padding: 0.46875em 1.25em 0.46875em 1.25em;
    border: 0.1rem solid #BE9F56; }
    .Nav--main .Nav-link.is-webshop:hover {
      color: #ffffff;
      background-color: #BE9F56; }
      .Nav--main .Nav-link.is-webshop:hover:before {
        transform: scaleX(0); }

.Header--light .Nav--main .Nav-link {
  color: #ffffff; }
  .Header--light .Nav--main .Nav-link:before {
    background: #ffffff; }
  .Header--light .Nav--main .Nav-link.is-active, .Header--light .Nav--main .Nav-link:active, .Header--light .Nav--main .Nav-link:focus, .Header--light .Nav--main .Nav-link:hover {
    color: #BE9F56; }
  .Header--light .Nav--main .Nav-link.is-webshop {
    padding: 0.3125em 1.25em 0.3125em 1.25em;
    border: 0.1rem solid #BE9F56;
    color: #BE9F56; }
    .Header--light .Nav--main .Nav-link.is-webshop:hover {
      color: #ffffff;
      background-color: #BE9F56; }
      .Header--light .Nav--main .Nav-link.is-webshop:hover:before {
        transform: scaleX(0); }

/* Lang */
.Nav--lang {
  text-align: right;
  width: 10rem;
  margin-right: 0;
  margin-left: auto; }
  .Nav--lang .Nav-list {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .Nav--lang .Nav-link {
    color: rgba(255, 255, 255, 0.65);
    padding: 0.5rem 0.25rem;
    text-transform: uppercase;
    transition: color 0.44s cubic-bezier(0.23, 1, 0.32, 1);
    letter-spacing: 0.05em; }
    .Nav--lang .Nav-link.is-active {
      color: #BE9F56; }
    .Nav--lang .Nav-link:not(.is-active):hover {
      color: #BE9F56; }

.Nav--footer .Nav-list {
  align-items: center; }
  .Nav--footer .Nav-list .Nav-item:first-child {
    margin-right: 1rem; }

.Nav--footer .Nav-link {
  color: rgba(0, 0, 0, 0.35);
  padding: 1rem;
  transition: color 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
  .Nav--footer .Nav-link.is-active .Nav--footer .Nav-link:focus, .Nav--footer .Nav-link:hover, .Nav--footer .Nav-link:active {
    color: rgba(0, 0, 0, 0.5); }

.Nav--footer-main .Nav-list,
.Nav--footer-extra .Nav-list {
  display: block; }

.Nav--footer-main .Nav-link,
.Nav--footer-extra .Nav-link {
  color: rgba(255, 255, 255, 0.65);
  padding: 0.5rem 1rem;
  margin-left: -1rem;
  transition: color 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
  .Nav--footer-main .Nav-link.is-active .Nav--footer-main .Nav-link:focus, .Nav--footer-main .Nav-link:hover, .Nav--footer-main .Nav-link:active,
  .Nav--footer-extra .Nav-link.is-active .Nav--footer-main .Nav-link:focus,
  .Nav--footer-extra .Nav-link:hover,
  .Nav--footer-extra .Nav-link:active, .Nav--footer-main .Nav-link.is-active
  .Nav--footer-extra .Nav-link:focus,
  .Nav--footer-extra .Nav-link.is-active
  .Nav--footer-extra .Nav-link:focus {
    color: #ffffff; }

.Navigation-side .Logo-object {
  max-width: 3.6rem; }

.Navigation-side .Nav--lang .Nav-link {
  color: rgba(255, 255, 255, 0.25); }
  .Navigation-side .Nav--lang .Nav-link.is-active {
    color: rgba(255, 255, 255, 0.5); }
  .Navigation-side .Nav--lang .Nav-link:not(.is-active):hover {
    color: rgba(255, 255, 255, 0.5); }

@media screen and (min-width: 48em) {
  .Navigation-side .Nav--socialMedia .Nav-list {
    justify-content: flex-end; } }

.Navigation-side .Nav--socialMedia .Nav-link {
  background: none;
  width: 1.7rem;
  height: 1.7rem;
  color: rgba(255, 255, 255, 0.65); }
  .Navigation-side .Nav--socialMedia .Nav-link:hover {
    background: none;
    color: #BE9F56; }

.Navigation-side .Nav--socialMedia .Icon {
  max-width: 1.7rem;
  max-height: 1.7rem; }

.Navigation-side .Navigation-body {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .Navigation-side .Navigation-body::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */ }

.Nav--side-main {
  font-size: 2.2rem;
  line-height: 100%;
  display: block;
  margin-top: 2rem; }
  @media screen and (min-width: 48em) {
    .Nav--side-main {
      text-align: right; } }
  .Nav--side-main .Nav-list {
    display: block; }
  .Nav--side-main .Nav-link {
    display: inline-block;
    position: relative;
    color: rgba(255, 255, 255, 0.65);
    padding: 1.5rem 1rem;
    transition: transform 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
    .Nav--side-main .Nav-link:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 2.5rem;
      height: 0.1rem;
      background: #BE9F56;
      transform-origin: right center;
      transform: translate(-100%, 0) scaleX(0);
      transition: transform 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
    .Nav--side-main .Nav-link.is-active, .Nav--side-main .Nav-link:active, .Nav--side-main .Nav-link:focus, .Nav--side-main .Nav-link:hover {
      color: #BE9F56;
      transform: translate(3.5rem, 0); }
      .Nav--side-main .Nav-link.is-active:before, .Nav--side-main .Nav-link:active:before, .Nav--side-main .Nav-link:focus:before, .Nav--side-main .Nav-link:hover:before {
        transform: translate(-100%, 0) scaleX(1); }
      @media screen and (min-width: 48em) {
        .Nav--side-main .Nav-link.is-active, .Nav--side-main .Nav-link:active, .Nav--side-main .Nav-link:focus, .Nav--side-main .Nav-link:hover {
          transform: none; } }

.Nav--sub {
  line-height: 100%;
  display: block;
  border-top: 0.5px solid rgba(255, 255, 255, 0.25);
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 0; }
  @media screen and (min-width: 48em) {
    .Nav--sub {
      text-align: right; } }
  .Nav--sub .Nav-list {
    display: block; }
  .Nav--sub .Nav-link {
    display: inline-block;
    position: relative;
    color: rgba(255, 255, 255, 0.65);
    padding: 1rem;
    transition: transform 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
    .Nav--sub .Nav-link:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 2.5rem;
      height: 0.1rem;
      background: #BE9F56;
      transform-origin: right center;
      transform: translate(-100%, 0) scaleX(0);
      transition: transform 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
    .Nav--sub .Nav-link.is-active, .Nav--sub .Nav-link:active, .Nav--sub .Nav-link:focus, .Nav--sub .Nav-link:hover {
      color: #BE9F56;
      transform: translate(3.5rem, 0); }
      .Nav--sub .Nav-link.is-active:before, .Nav--sub .Nav-link:active:before, .Nav--sub .Nav-link:focus:before, .Nav--sub .Nav-link:hover:before {
        transform: translate(-100%, 0) scaleX(1); }
      @media screen and (min-width: 48em) {
        .Nav--sub .Nav-link.is-active, .Nav--sub .Nav-link:active, .Nav--sub .Nav-link:focus, .Nav--sub .Nav-link:hover {
          transform: none; } }

.Nav--socialMedia .Nav-list {
  margin-left: -0.5rem; }

.Nav--socialMedia .Nav-link {
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #ffffff;
  background: #BE9F56;
  margin: 0.5rem;
  transition: background 0.44s cubic-bezier(0.23, 1, 0.32, 1), color 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
  .Nav--socialMedia .Nav-link:hover, .Nav--socialMedia .Nav-link:focus, .Nav--socialMedia .Nav-link:active {
    background: #F7F7F7;
    color: rgba(0, 0, 0, 0.85); }
  @media screen and (min-width: 48em) {
    .Nav--socialMedia .Nav-link {
      width: 2.5rem;
      height: 2.5rem; } }

.Nav--socialMedia .Icon {
  max-width: 2.4rem;
  max-height: 2.4rem;
  width: 100%; }
  @media screen and (min-width: 48em) {
    .Nav--socialMedia .Icon {
      max-width: 1.5rem;
      max-height: 1.4rem; } }

.Nav--pagination {
  text-align: center;
  margin-top: 4rem; }
  .Nav--pagination .Nav-list {
    justify-content: center;
    align-items: center; }
  .Nav--pagination .Nav-link {
    border-radius: 50%;
    width: 4.5rem;
    height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.44s cubic-bezier(0.23, 1, 0.32, 1), background-color 0.44s cubic-bezier(0.23, 1, 0.32, 1);
    margin: 0; }
    .Nav--pagination .Nav-link.is-active, .Nav--pagination .Nav-link:hover, .Nav--pagination .Nav-link:focus {
      background-color: #BE9F56;
      color: #ffffff; }
  .Nav--pagination .Nav-link--prev,
  .Nav--pagination .Nav-link--next {
    color: #BE9F56; }
    .Nav--pagination .Nav-link--prev.is-active, .Nav--pagination .Nav-link--prev:hover, .Nav--pagination .Nav-link--prev:focus,
    .Nav--pagination .Nav-link--next.is-active,
    .Nav--pagination .Nav-link--next:hover,
    .Nav--pagination .Nav-link--next:focus {
      background-color: unset;
      color: rgba(0, 0, 0, 0.85); }
    .Nav--pagination .Nav-link--prev .Icon,
    .Nav--pagination .Nav-link--next .Icon {
      width: 1.7rem; }
  .Nav--pagination .Nav-link--next {
    transform: scale(-1, 1); }

/* Fields */
/* Fields: field */
.Form-field--input,
.Form-field--textarea, .Form-field--select, .Form-field--select[multiple] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  border: 1px solid rgba(190, 159, 86, 0.4);
  color: #424242;
  vertical-align: middle;
  width: 100%;
  background: none;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 0px;
  -webkit-border-radius: 0px; }
  .Form-item.is-error .Form-field--input,
  .Form-item.is-error .Form-field--textarea, .Form-item.is-error .Form-field--select {
    border-color: #f44336; }
  .Form-item.is-success .Form-field--input,
  .Form-item.is-success .Form-field--textarea, .Form-item.is-success .Form-field--select {
    border-color: #4caf50; }

.Form-field--select {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); }
  .Form-field--select:focus {
    border-color: #757575;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px #64b5f6;
    outline: 0;
    z-index: 1; }
    .Form-item.is-error .Form-field--select:focus {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(244, 67, 54, 0.6); }
    .Form-item.is-success .Form-field--select:focus {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(76, 175, 80, 0.6); }

.Form-field {
  font-family: inherit;
  position: relative; }

.Form-indicator {
  user-select: none; }

.Form-field--xs {
  max-width: 6rem !important; }

.Form-field--sm {
  max-width: 10rem !important; }

.Form-field--md {
  max-width: 20rem !important; }

.Form-field--lg {
  max-width: 30rem !important; }

.Form-field--xl {
  max-width: 40rem !important; }

.Form-field--full {
  max-width: none !important; }

.Form-label {
  font-weight: 300;
  color: #BE9F56;
  margin-left: 1.5rem;
  padding: 0 0.5rem;
  transition: transform 0.44s cubic-bezier(0.23, 1, 0.32, 1);
  background: #ffffff;
  display: inline-block;
  position: relative;
  z-index: 1; }

.Form-item--input .Form-controls {
  margin-top: -5rem;
  margin-bottom: 4rem; }

.Form-item.is-active .Form-label {
  transform: translate3d(0, -2.5rem, 0); }

.Form-button {
  position: relative; }
  .Form-button .Icon {
    position: absolute;
    top: 0;
    left: 2rem;
    bottom: 0;
    margin: auto;
    width: 0.6rem;
    color: #ffffff; }
  .Form-button .Button {
    padding-left: 4rem; }

/* Fields: check */
.Form-field--radio,
.Form-field--checkbox {
  cursor: pointer;
  display: flex;
  user-select: none; }
  .Form-field--radio:not(:first-of-type),
  .Form-field--checkbox:not(:first-of-type) {
    margin-top: 0.25rem; }
  .Form-field--radio input[type="radio"],
  .Form-field--radio input[type="checkbox"],
  .Form-field--checkbox input[type="radio"],
  .Form-field--checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .Form-field--radio input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
      background-color: #757575;
      border-color: #757575; }
    .Form-field--radio input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:focus ~ .Form-indicator {
      border-color: #757575;
      box-shadow: 0 0 2px 1px #64b5f6; }
  .Form-field--radio .Form-indicator,
  .Form-field--checkbox .Form-indicator {
    background-color: #ffffff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 0.5625em 0.5625em;
    border: 0.1rem solid #BE9F56;
    flex-shrink: 0;
    height: 1em;
    margin-right: 1rem;
    width: 1em;
    position: relative;
    top: 0.25em; }

.Form-field--radio > .Form-indicator {
  border-radius: 100%; }

.Form-field--radio input[type="radio"]:checked ~ .Form-indicator::after {
  background-color: #ffffff;
  border-radius: 100%;
  content: '';
  height: 0.3em;
  width: 0.3em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.Form-field--checkbox > .Form-indicator {
  border-radius: 0; }

.Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.8 6.3'%3E%3Ctitle%3ECheck%3C/title%3E%3Cpath d='M6.4,0,5.7.7,2.9,3.5l-.8-.8L1.4,2,0,3.4l.7.7L2.2,5.6l.7.7.7-.7L7.1,2.1l.7-.7Z' fill='%23fff'/%3E%3C/svg%3E"); }

/* Fields: file */
.Form-field--file {
  cursor: pointer;
  display: block; }
  .Form-field--file input[type='file'] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .Form-field--file input[type='file']:focus ~ .Form-indicator {
      border-color: #757575;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px #64b5f6; }
      .Form-item.is-error .Form-field--file input[type='file']:focus ~ .Form-indicator {
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(244, 67, 54, 0.6); }
      .Form-item.is-success .Form-field--file input[type='file']:focus ~ .Form-indicator {
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) , 0 0 2px 1px rgba(76, 175, 80, 0.6); }
  .Form-field--file .Form-indicator {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #ffffff;
    border-radius: 0;
    border: 1px solid #BE9F56;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    color: #424242;
    display: block;
    line-height: normal;
    overflow: hidden;
    padding: 1.25em 1.25em 1.25em 1.25em;
    position: relative;
    white-space: nowrap;
    width: 100%; }
    .Form-field--file .Form-indicator::before {
      content: attr(data-file-text);
      display: block;
      padding-right: 10rem; }
    .Form-field--file .Form-indicator::after {
      background-color: #eeeeee;
      border-left: 1px solid #BE9F56;
      color: #757575;
      content: attr(data-file-button);
      padding: 1.25em 1.25em 1.25em 1.25em;
      text-align: center;
      width: 10rem;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0; }
  @media screen and (min-width: 37.5em) {
    .Form-field--file {
      max-width: 30rem; } }

/* Fields: input */
.Form-field--input {
  padding: 1.25em 1.25em 1.25em 1.25em; }

.Form-field--textarea {
  padding: 1.125em 1.25em 1.125em 1.25em; }

/* Fields: select */
select {
  cursor: pointer;
  width: 100% !important; }
  @media screen and (min-width: 37.5em) {
    select {
      max-width: 30rem; } }

.Form-field--select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20292.36%20402%22%3E%3Cpath%20d%3D%22M18.27%2C164.45H274.09a18.17%2C18.17%2C0%2C0%2C0%2C12.85-31.12L159%2C5.42a17.93%2C17.93%2C0%2C0%2C0-25.7%2C0L5.42%2C133.33a17.93%2C17.93%2C0%2C0%2C0%2C0%2C25.7A17.57%2C17.57%2C0%2C0%2C0%2C18.27%2C164.45Z%22%2F%3E%3Cpath%20d%3D%22M274.09%2C237.55H18.27A17.57%2C17.57%2C0%2C0%2C0%2C5.42%2C243a17.93%2C17.93%2C0%2C0%2C0%2C0%2C25.7l127.91%2C127.9a17.92%2C17.92%2C0%2C0%2C0%2C25.7%2C0L286.93%2C268.66a18.27%2C18.27%2C0%2C0%2C0-12.85-31.12Z%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  line-height: normal;
  overflow: hidden;
  padding: 1.25em 2.5em 1.25em 1.25em; }

.Form-field--select[multiple] {
  height: auto; }
  .Form-field--select[multiple] option {
    padding: 0.625em 1.25em 0.625em 1.25em; }
    .Form-field--select[multiple] option + option {
      border-top: 0.1rem solid #BE9F56; }

.Form-item--html p {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 2.3rem; }

/* Slider */
.Slider {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-flow: row nowrap;
  overflow-x: hidden;
  margin-left: -0.5rem; }
  .Slider--social {
    padding-bottom: 8rem; }
    .Slider--social .Nav-list {
      justify-content: center; }

.Slider-item {
  margin: 0 0.5rem; }
  .Slider-item--square {
    margin: 0 1rem; }
    .Slider-item--square .Slider-figure-image {
      height: 44rem; }

.Slider-figure-image {
  max-width: unset;
  height: 44rem;
  width: 44rem;
  object-fit: cover;
  object-position: center center; }

/* Gallery */
@font-face {
  font-family: 'lg';
  src: url("../fonts/gallery/lg.ttf?22t19m") format("truetype"), url("../fonts/gallery/lg.woff?22t19m") format("woff"), url("../fonts/gallery/lg.svg?22t19m#lg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

.lg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lg' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  outline: none;
  border: none;
  background-color: transparent; }
  .lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color: #FFF; }

.lg-actions .lg-next {
  right: 20px; }
  .lg-actions .lg-next:before {
    content: "\e095"; }

.lg-actions .lg-prev {
  left: 20px; }
  .lg-actions .lg-prev:after {
    content: "\e094"; }

@-webkit-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-webkit-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative; }

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative; }

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.45); }
  .lg-toolbar .lg-icon {
    color: #999;
    cursor: pointer;
    float: right;
    font-size: 24px;
    height: 47px;
    line-height: 27px;
    padding: 10px 0;
    text-align: center;
    width: 50px;
    text-decoration: none !important;
    outline: medium none;
    background: none;
    border: none;
    box-shadow: none;
    -webkit-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear; }
    .lg-toolbar .lg-icon:hover {
      color: #FFF; }
  .lg-toolbar .lg-close:after {
    content: "\e070"; }
  .lg-toolbar .lg-download:after {
    content: "\e0f2"; }

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080; }
  .lg-sub-html h4 {
    margin: 0;
    font-size: 13px;
    font-weight: bold; }
  .lg-sub-html p {
    font-size: 12px;
    margin: 5px 0 0; }

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle; }

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }
  .lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important; }

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0); }

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px; }

.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px; }
  @media (min-width: 1025px) {
    .lg-outer .lg-thumb-item {
      -webkit-transition: border-color 0.25s ease;
      -o-transition: border-color 0.25s ease;
      transition: border-color 0.25s ease; } }
  .lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
    border-color: #a90707; }
  .lg-outer .lg-thumb-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px; }

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0; }

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease; }

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px; }

.lg-outer .lg-toggle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px;
  outline: medium none;
  border: none; }
  .lg-outer .lg-toggle-thumb:after {
    content: "\e1ff"; }
  .lg-outer .lg-toggle-thumb:hover {
    color: #FFF; }

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px; }

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative; }
  .lg-outer .lg-video .lg-object {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }
  .lg-outer .lg-video .lg-video-play {
    width: 84px;
    height: 59px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -42px;
    margin-top: -30px;
    z-index: 1080;
    cursor: pointer; }

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../img/gallery/vimeo-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../img/gallery/vimeo-play.png") no-repeat scroll 0 -58px transparent; }

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../img/gallery/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8; }

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1; }

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../img/gallery/youtube-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../img/gallery/youtube-play.png") no-repeat scroll 0 -60px transparent; }

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0; }

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible; }

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s; }
  .lg-progress-bar .lg-progress {
    background-color: #a90707;
    height: 5px;
    width: 0; }
  .lg-progress-bar.lg-start .lg-progress {
    width: 100%; }
  .lg-show-autoplay .lg-progress-bar {
    opacity: 1; }

.lg-autoplay-button:after {
  content: "\e01d"; }
  .lg-show-autoplay .lg-autoplay-button:after {
    content: "\e01a"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

#lg-zoom-in:after {
  content: "\e311"; }

#lg-actual-size {
  font-size: 20px; }
  #lg-actual-size:after {
    content: "\e033"; }

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none; }
  #lg-zoom-out:after {
    content: "\e312"; }
  .lg-zoomed #lg-zoom-out {
    opacity: 1;
    pointer-events: auto; }

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px; }
  .lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
    overflow: visible; }

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px; }
  .lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
    box-shadow: 0 0 0 2px white inset; }

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0); }
  .lg-outer .lg-pager-thumb-cont img {
    width: 100%;
    height: 100%; }

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px; }
  .lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
    box-shadow: 0 0 0 8px white inset; }

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0; }

.lg-fullscreen:after {
  content: "\e20c"; }
  .lg-fullscreen-on .lg-fullscreen:after {
    content: "\e20d"; }

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s; }

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible; }

.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF; }

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; }
  .lg-outer .lg-dropdown:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    border: 8px solid transparent;
    border-bottom-color: #FFF;
    right: 16px;
    top: -16px; }
  .lg-outer .lg-dropdown > li:last-child {
    margin-bottom: 0px; }
  .lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
    color: #333; }
  .lg-outer .lg-dropdown a {
    color: #333;
    display: block;
    white-space: pre;
    padding: 4px 12px;
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px; }
    .lg-outer .lg-dropdown a:hover {
      background-color: rgba(0, 0, 0, 0.07); }
  .lg-outer .lg-dropdown .lg-dropdown-text {
    display: inline-block;
    line-height: 1;
    margin-top: -3px;
    vertical-align: middle; }
  .lg-outer .lg-dropdown .lg-icon {
    color: #333;
    display: inline-block;
    float: none;
    font-size: 20px;
    height: auto;
    line-height: 1;
    margin-right: 8px;
    padding: 0;
    vertical-align: middle;
    width: auto; }

.lg-outer #lg-share {
  position: relative; }
  .lg-outer #lg-share:after {
    content: "\e80d"; }

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998; }
  .lg-outer #lg-share-facebook .lg-icon:after {
    content: "\e904"; }

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced; }
  .lg-outer #lg-share-twitter .lg-icon:after {
    content: "\e907"; }

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39; }
  .lg-outer #lg-share-googleplus .lg-icon:after {
    content: "\e905"; }

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027; }
  .lg-outer #lg-share-pinterest .lg-icon:after {
    content: "\e906"; }

.lg-outer .lg-img-rotate {
  position: absolute;
  padding: 0 5px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s;
  transition: transform 0.3s cubic-bezier(0.32, 0, 0.67, 0) 0s; }

.lg-rotate-left:after {
  content: "\e900"; }

.lg-rotate-right:after {
  content: "\e901"; }

.lg-icon.lg-flip-hor, .lg-icon.lg-flip-ver {
  font-size: 26px; }

.lg-flip-hor:after {
  content: "\e902"; }

.lg-flip-ver:after {
  content: "\e903"; }

.lg-group {
  *zoom: 1; }

.lg-group:before, .lg-group:after {
  display: table;
  content: "";
  line-height: 0; }

.lg-group:after {
  clear: both; }

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  outline: none;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-outer * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .lg-outer.lg-visible {
    opacity: 1; }
  .lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important; }
  .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
    opacity: 1; }
  .lg-outer.lg-grab img.lg-object {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer.lg-grabbing img.lg-object {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%; }
  .lg-outer .lg-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap; }
  .lg-outer .lg-item {
    background: url("../img/gallery/loading.gif") no-repeat scroll center center transparent;
    display: none !important; }
  .lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
    display: inline-block !important; }
  .lg-outer.lg-css .lg-current {
    display: inline-block !important; }
  .lg-outer .lg-item, .lg-outer .lg-img-wrap {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%; }
    .lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px; }
  .lg-outer .lg-img-wrap {
    position: absolute;
    padding: 0 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .lg-outer .lg-item.lg-complete {
    background-image: none; }
  .lg-outer .lg-item.lg-current {
    z-index: 1060; }
  .lg-outer .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important; }
  .lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
    opacity: 0;
    -webkit-transition: opacity 0.15s ease 0s;
    -o-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s; }
  .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
    opacity: 1; }
  .lg-outer .lg-empty-html {
    display: none; }
  .lg-outer.lg-hide-download #lg-download {
    display: none; }

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-backdrop.in {
    opacity: 1; }

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item {
  opacity: 0; }
  .lg-css3.lg-fade .lg-item.lg-current {
    opacity: 1; }
  .lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
    -webkit-transition: opacity 0.1s ease 0s;
    -moz-transition: opacity 0.1s ease 0s;
    -o-transition: opacity 0.1s ease 0s;
    transition: opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
    left: -100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
    left: 100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    left: 0;
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

/* DigitalPulse */
.DigitalPulse {
  color: currentColor;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  margin-left: auto;
  height: 1.5em; }
  .DigitalPulse-icon, .DigitalPulse-body {
    display: block;
    fill: currentColor; }
  .DigitalPulse-label {
    margin-right: .3em;
    flex-shrink: 0; }
  .DigitalPulse-object {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .DigitalPulse-icon {
    flex-shrink: 0;
    height: 1.85743em;
    transform: translateY(5%);
    width: 1.5em; }
  .DigitalPulse-body {
    opacity: 0; }
  @media screen and (min-width: 64em) {
    .DigitalPulse-object {
      min-width: 5.56167em;
      position: relative;
      z-index: 1;
      position: absolute;
      top: 50%;
      right: 0;
      transform: scale(1.01) translateY(-50%);
      transition: right 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
    .DigitalPulse:hover .DigitalPulse-object {
      color: currentColor;
      right: 100%; }
    .DigitalPulse:hover .DigitalPulse-object {
      color: currentColor;
      right: 100%; }
    .DigitalPulse:hover .DigitalPulse-body {
      opacity: 1; }
    .DigitalPulse-body {
      -webkit-transition: width, 300ms, ease-out;
      -o-transition: width, 300ms, ease-out;
      transition: width, 300ms, ease-out;
      margin-left: .3em;
      transform: translateY(12.5%);
      display: flex;
      justify-content: flex-end;
      height: 0.94828em;
      width: 5.56167em; } }

.Hero {
  position: relative;
  padding-top: 22rem;
  padding-bottom: 8rem;
  color: #ffffff; }
  .Hero--beer {
    padding-bottom: 2rem; }
  .Hero:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85); }

@media screen and (max-width: 63.9375em) {
  .Hero + .Text-image {
    margin-top: 12rem; } }

.Hero-header {
  margin-bottom: 2rem;
  max-width: 66rem; }
  @media screen and (min-width: 48em) {
    .Hero-header {
      padding-left: 10rem; } }
  .Hero-header h3 {
    margin-bottom: 1.5rem;
    font-size: 3rem;
    line-height: 1.2; }
    @media screen and (min-width: 80em) {
      .Hero-header h3 {
        margin-bottom: 1.5rem;
        font-size: 6rem;
        line-height: 1.1; } }
    @media screen and (min-width: 64em) and (max-width: 79.9375em) {
      .Hero-header h3 {
        margin-bottom: 1.5rem;
        font-size: 4.5rem;
        line-height: 1.2; } }

.Hero-figure-object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.Hero-figure-image {
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center; }

.Hero-content {
  position: relative;
  z-index: 5; }
  .Hero-content--small {
    max-width: 60rem;
    margin: auto; }

.Hero-description h3 {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.6rem;
  line-height: 2.9rem;
  text-transform: uppercase;
  color: #BE9F56; }

.Hero-list {
  border-top: 0.1rem solid rgba(190, 159, 86, 0.4);
  padding-top: 2rem; }

.Hero-list-item {
  display: flex; }

.Hero-list-label {
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: #BE9F56;
  text-transform: uppercase;
  letter-spacing: 1px; }

.Hero-logo {
  opacity: 0.05;
  width: 35rem;
  position: absolute;
  top: 17rem;
  left: -4rem; }
  .Hero-logo path {
    fill: #ffffff; }
  .Hero-logo path:first-child {
    fill: none; }

.Hero-pointer {
  position: absolute;
  z-index: 1;
  color: #ffffff;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: auto;
  text-transform: uppercase;
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  padding-bottom: 4rem;
  animation: float 5s ease-in-out infinite; }
  .Hero-pointer:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    width: 1px;
    height: 50px;
    background: #ffffff;
    transform: translateY(50%); }
  @media screen and (min-width: 37.5em) {
    .Hero-pointer {
      padding-bottom: 8rem; }
      .Hero-pointer:after {
        height: 100px; } }

.Hero-figure-side-object {
  margin-bottom: -16rem;
  position: relative;
  z-index: 10;
  height: 40rem; }

.Hero-figure-side-image {
  object-fit: cover;
  object-position: center center;
  max-width: unset;
  width: 100%;
  height: 100%; }

.Hero-figure-beer-object {
  position: relative;
  z-index: 10;
  max-width: 70%;
  margin: auto auto 4rem auto; }
  @media screen and (min-width: 48em) {
    .Hero-figure-beer-object {
      max-width: 27.5rem; } }
  @media screen and (min-width: 64em) {
    .Hero-figure-beer-object {
      margin-bottom: -12rem;
      height: 70rem;
      transform: translate(0, 5.5rem);
      max-width: unset; } }
  @media screen and (min-width: 81.25em) {
    .Hero-figure-beer-object {
      transform: translate(-8rem, 0); } }

.Hero-figure-beer-image {
  height: 100%;
  object-fit: contain; }

.Hero-awards {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.Hero-awards-item {
  flex-basis: 4.5rem;
  margin-right: 4rem;
  transform: scale(1.3); }

.Hero-category,
.Hero-subtitle {
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: #BE9F56;
  letter-spacing: 1px;
  text-transform: uppercase; }

.Hero--extended {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 14rem; }
  .Hero--extended:before, .Hero--extended:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
  .Hero--extended:before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.85) 0.01%, rgba(0, 0, 0, 0) 45.31%); }
  .Hero--extended:after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 50%); }

.Share,
.Share-list {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.Share-list {
  padding: 0;
  list-style: none;
  margin: 0;
  margin-left: 1rem;
  flex-wrap: nowrap; }

.Share-label {
  font-family: "Garamond", serif;
  letter-spacing: 1;
  text-transform: uppercase;
  color: #BE9F56; }

.Share-link {
  background: #BE9F56;
  color: #ffffff;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .Share-link .Icon {
    height: 1.1rem;
    width: 1.1rem; }
  .Share-link .Icon--facebook {
    height: 1.5rem;
    width: 1.5rem; }

/**
 *  MEDIUM VERSION
 */
.Preview-list {
  margin: 7.5rem 0; }
  @media screen and (min-width: 48em) {
    .Preview-list {
      margin: 10rem 0; } }
  @media screen and (min-width: 64em) {
    .Preview-list {
      margin: 12.5rem 0; } }

.Preview {
  background: #F7F7F7;
  height: 100%; }
  .Preview--light {
    background: #ffffff; }
    .Preview--light .Preview-header {
      background: #ffffff; }

.Preview-header {
  padding: 4rem 2rem;
  background: #F7F7F7;
  transition: transform 0.44s cubic-bezier(0.23, 1, 0.32, 1); }

.Preview-figure-object {
  height: 19.5rem; }

.Preview-figure-image {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover; }

.fonts-loaded .Preview-title {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 1px;
  margin: 0;
  text-transform: none;
  color: rgba(0, 0, 0, 0.85); }

.Preview-link {
  color: rgba(0, 0, 0, 0.85); }
  .Preview-link:hover, .Preview-link:focus {
    color: rgba(0, 0, 0, 0.85); }
    .Preview-link:hover .Preview-header, .Preview-link:focus .Preview-header {
      transform: translateY(-1rem);
      color: rgba(0, 0, 0, 0.85); }

/**
 *  SMALL VERSION
 */
.Preview-small {
  background: #ffffff;
  margin-bottom: 2rem; }

.Preview-category {
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: #BE9F56;
  letter-spacing: 1px; }

.Preview-button {
  color: #BE9F56;
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .Preview-button .Icon {
    width: 0.4rem; }
  .Preview-button .Icon-wrapper {
    border: 0.1rem solid rgba(190, 159, 86, 0.35);
    border-radius: 50%;
    width: 2.6rem;
    height: 2.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem; }

.Preview-small-content {
  padding: 0 2rem; }

.Preview-small-title {
  font-size: 1.6rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85); }

.Preview-small-figure {
  position: relative;
  overflow: hidden;
  width: 11rem; }
  .Preview-small-figure:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%; }
  .Preview-small-figure > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.Preview-small-figure-object {
  height: 100%; }

.Preview-small-figure-image {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover; }

.fonts-loaded .Preview-small-title {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }

/**
 *  LARGE VERSION
 */
.Preview-large-content {
  padding: 4rem; }

.Preview-large-figure-object {
  height: 100%; }

.Preview-large-figure-image {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover; }

.Hours-item {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem; }

.Hours-day {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  flex-basis: 7rem;
  font-weight: 700; }

.Hours-hour {
  font-weight: 300; }

.Hours-closing {
  color: rgba(0, 0, 0, 0.85);
  opacity: 0.55;
  font-style: italic;
  font-weight: 300;
  margin-bottom: 2rem; }

/**
 *  MEDIUM VERSION
 */
.Preview-list {
  margin: 7.5rem 0; }
  @media screen and (min-width: 48em) {
    .Preview-list {
      margin: 10rem 0; } }
  @media screen and (min-width: 64em) {
    .Preview-list {
      margin: 12.5rem 0; } }

.Preview {
  background: #F7F7F7;
  height: 100%; }
  .Preview--light {
    background: #ffffff; }
    .Preview--light .Preview-header {
      background: #ffffff; }

.Preview-header {
  padding: 4rem 2rem;
  background: #F7F7F7;
  transition: transform 0.44s cubic-bezier(0.23, 1, 0.32, 1); }

.Preview-figure-object {
  height: 19.5rem; }

.Preview-figure-image {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover; }

.fonts-loaded .Preview-title {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 1px;
  margin: 0;
  text-transform: none;
  color: rgba(0, 0, 0, 0.85); }

.Preview-link {
  color: rgba(0, 0, 0, 0.85); }
  .Preview-link:hover, .Preview-link:focus {
    color: rgba(0, 0, 0, 0.85); }
    .Preview-link:hover .Preview-header, .Preview-link:focus .Preview-header {
      transform: translateY(-1rem);
      color: rgba(0, 0, 0, 0.85); }

/**
 *  SMALL VERSION
 */
.Preview-small {
  background: #ffffff;
  margin-bottom: 2rem; }

.Preview-category {
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: #BE9F56;
  letter-spacing: 1px; }

.Preview-button {
  color: #BE9F56;
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .Preview-button .Icon {
    width: 0.4rem; }
  .Preview-button .Icon-wrapper {
    border: 0.1rem solid rgba(190, 159, 86, 0.35);
    border-radius: 50%;
    width: 2.6rem;
    height: 2.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem; }

.Preview-small-content {
  padding: 0 2rem; }

.Preview-small-title {
  font-size: 1.6rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85); }

.Preview-small-figure {
  position: relative;
  overflow: hidden;
  width: 11rem; }
  .Preview-small-figure:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%; }
  .Preview-small-figure > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.Preview-small-figure-object {
  height: 100%; }

.Preview-small-figure-image {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover; }

.fonts-loaded .Preview-small-title {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }

/**
 *  LARGE VERSION
 */
.Preview-large-content {
  padding: 4rem; }

.Preview-large-figure-object {
  height: 100%; }

.Preview-large-figure-image {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover; }

.Cta-content {
  background: #BE9F56;
  padding: 4rem 2rem;
  color: #ffffff;
  position: relative;
  height: 100%; }
  @media screen and (min-width: 48em) {
    .Cta-content {
      padding: 4rem 8rem; } }
  @media screen and (min-width: 64em) {
    .Cta-content {
      padding: 8rem 12rem; } }
  .Cta-content--reversed .Cta-logo {
    left: 0;
    right: auto;
    transform: translateX(-40%); }
  .Cta-content--full {
    background: rgba(190, 159, 86, 0.85); }

/*
    IMAGE
*/
.Cta-bg-object {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1; }
  .Cta-bg-object + .Cta-content {
    margin-top: -6rem; }
    @media screen and (min-width: 48em) {
      .Cta-bg-object + .Cta-content {
        margin-top: -8rem; } }
    @media screen and (min-width: 64em) {
      .Cta-bg-object + .Cta-content {
        margin-top: -10rem; } }

.Cta-figure-object {
  width: 100%;
  height: 100%;
  position: relative; }

.Cta-figure-image,
.Cta-bg-image {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center center; }

@media screen and (min-width: 48em) {
  .Cta-figure-image {
    position: absolute;
    top: 0;
    left: 0; } }

/*
    LOGO
*/
.Cta-logo {
  opacity: 0.1;
  width: 17.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
  transform: translateX(40%); }
  .Cta-logo path {
    fill: #ffffff; }
  .Cta-logo path:first-child {
    fill: none; }

.Cta-logo-small {
  width: 3.2rem;
  margin-bottom: 2rem; }

.Author {
  display: flex;
  justify-content: flex-start; }
  .Author-figure-image {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.Author-figure-object {
  border-radius: 100%;
  width: 6rem;
  height: 6rem;
  margin-right: 2rem; }

.Divider {
  width: 0.1rem;
  height: 10rem;
  margin: auto;
  background: #BE9F56;
  margin-bottom: 4rem; }

.Cta-grid-item {
  height: 100%;
  width: 100%; }
  .Cta-grid-item.u-bg-gold h3 {
    color: #ffffff; }
  .Cta-grid-item a {
    text-decoration: none;
    color: inherit; }
  .Cta-grid-item h3 {
    margin-bottom: 0; }
  .Cta-grid-item--center .Cta-grid-link,
  .Cta-grid-item--center .Cta-grid-inner {
    align-items: center; }
  .Cta-grid-item--center .Cta-grid-image-object {
    max-width: 20rem; }
  .Cta-grid-item.u-bg-light .Cta-grid-image-bg-object:after {
    background: #F7F7F7;
    opacity: 0.85; }
  .Cta-grid-item.u-bg-dark .Cta-grid-image-bg-object:after {
    background: rgba(0, 0, 0, 0.85);
    opacity: 0.85; }
  .Cta-grid-item.u-bg-dark h3 {
    color: #BE9F56; }
  .Cta-grid-item.u-bg-gold .Cta-grid-image-bg-object:after {
    background: #BE9F56;
    opacity: 0.95; }
  .Cta-grid-item.u-bg-gold .Cta-grid-subtitle {
    color: #ffffff; }

.Cta-grid-link,
.Cta-grid-inner {
  display: block;
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  height: 100%;
  width: 100%; }
  @media screen and (min-width: 64em) {
    .Cta-grid-link,
    .Cta-grid-inner {
      padding: 5rem 4rem; } }

.Cta-grid-content {
  position: relative;
  z-index: 1; }

.Cta-grid-image-object {
  margin-bottom: 4rem; }

.Cta-grid-image-bg-object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .Cta-grid-image-bg-object:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.Cta-grid-image-bg-image {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%; }

.Cta-grid-tag,
.Cta-grid-subtitle {
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.6rem;
  letter-spacing: 1px;
  text-transform: uppercase; }

.Cta-grid-subtitle {
  margin-bottom: 0.5rem; }

.Cta-grid-tag {
  padding: 0.5rem 1rem;
  background: #BE9F56;
  color: #ffffff;
  display: inline-block;
  margin-bottom: 2rem; }

.Cta-grid-subtitle {
  color: #BE9F56; }

.Category-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }

.Category-item {
  margin: 2rem 0.5rem; }

.Category-link {
  font-size: 1.6rem;
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  text-align: none;
  color: #BE9F56;
  border: 0.1rem solid #BE9F56;
  padding: 1rem 2rem;
  border-radius: 4rem;
  transition: color 0.44s cubic-bezier(0.23, 1, 0.32, 1), background-color 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
  .Category-link:hover, .Category-link.is-active {
    color: #ffffff;
    background-color: #BE9F56; }

.Search-form {
  max-width: 55rem;
  width: 100%; }
  .Search-form .Form-controls {
    margin-top: -3.7rem;
    margin-bottom: 1rem; }
  .Search-form .Form-item {
    margin: 0; }
  .Search-form .Form-item.is-active .Form-label {
    transform: translate3d(0, -2rem, 0);
    background: #262626; }

.Search-input {
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  border: 0.1rem solid rgba(255, 255, 255, 0.3);
  padding: 1rem 2rem;
  color: #ffffff;
  transition: border 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
  .Search-input:focus {
    border-color: #ffffff; }
  .Search-input:focus ~ .Search-button {
    color: #ffffff; }

.Search-label {
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  background: none;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.3);
  margin-left: 2rem; }

.Search-button {
  color: rgba(255, 255, 255, 0.3);
  position: absolute;
  right: 2rem;
  padding: 1rem;
  margin-right: -1rem;
  transition: color 0.44s cubic-bezier(0.23, 1, 0.32, 1); }

.Search-icon {
  width: 1.5rem; }

.Search-list {
  padding: 0;
  margin: 0;
  border-bottom: 0.1rem solid rgba(190, 159, 86, 0.5);
  max-width: 87.6rem; }

.Search-list-item {
  border-top: 0.1rem solid rgba(190, 159, 86, 0.5);
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

.fonts-loaded .Search-title {
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }

.Search-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.6rem; }

.Search-content {
  width: 90%; }

.Search-arrow {
  width: 2.1rem;
  height: 2.1rem;
  margin-left: 1rem; }
  .Search-arrow .Icon {
    width: 0.4rem; }

.cookie-though {
  padding: 2rem;
  z-index: 499;
  line-height: 1.5rem;
  --ct-primary-400: #000 !important;
  --ct-primary-300: #BE9F56 !important;
  --ct-primary-200: #dfbd6fce;
  --ct-primary-100: #F7F7F7;
  --ct-text: #6b7280;
  --ct-subtitle: var(--ct-primary-400);
  --ct-title: var(--ct-primary-400);
  --ct-cookie: var(--ct-primary-400);
  --ct-policy: #fff;
  --ct-policy-enabled: #f0dbaace;
  --ct-policy-text: var(--ct-primary-400);
  --ct-policy-text-enabled: #000;
  --ct-policy-essential: var(--ct-primary-200);
  --ct-slider-enabled-primary: var(--ct-primary-200);
  --ct-slider-enabled-secondary: var(--ct-primary-300);
  --ct-primary-font: "Roboto", sans-serif !important;
  --ct-secondary-font: 'Roboto', sans-serif i !important;
  --ct-text-font-size: 11px;
  --ct-button-font-size: 11px; }
  @media screen and (max-width: 37.5em) {
    .cookie-though {
      padding: 0rem; } }

/* Header */
.Header {
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #ffffff;
  width: 100%;
  transition: cubic-bezier(0.23, 1, 0.32, 1) 0.44s;
  position: fixed;
  top: 0; }
  .Header > .Container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .Header.Scrolling {
    background-color: rgba(0, 0, 0, 0.85); }

/* Logo */
.Logo {
  flex-shrink: 0; }
  .Logo svg {
    display: block;
    width: 14rem; }
    @media screen and (min-width: 64em) {
      .Logo svg {
        width: 18.3rem; } }

/* General */
.LazyLoad,
.LazyLoading {
  opacity: 0; }

.LazyLoaded {
  -webkit-transition: opacity, 300ms;
  -o-transition: opacity, 300ms;
  transition: opacity, 300ms;
  opacity: 1; }

body:not(.is-tabbing) span:focus,
body:not(.is-tabbing) button:focus,
body:not(.is-tabbing) input:focus,
body:not(.is-tabbing) select:focus,
body:not(.is-tabbing) textarea:focus {
  outline: none; }

body {
  width: 100vw;
  overflow-x: hidden; }

/* Blocks */
.Block-spaced {
  margin: 6rem 0; }
  @media screen and (min-width: 48em) {
    .Block-spaced {
      margin: 8rem 0; } }
  @media screen and (min-width: 64em) {
    .Block-spaced {
      margin: 10rem 0; } }

.Block-spaced--inside {
  padding: 6rem 0; }
  @media screen and (min-width: 48em) {
    .Block-spaced--inside {
      padding: 8rem 0; } }
  @media screen and (min-width: 64em) {
    .Block-spaced--inside {
      padding: 10rem 0; } }

.Text-image {
  min-height: 65rem;
  display: flex;
  align-items: center; }
  .Text-image-content {
    max-width: 52.2rem;
    z-index: 101; }
    @media screen and (max-width: 64em) {
      .Text-image-content {
        max-width: 100%; } }
    @media screen and (max-width: 37.5em) {
      .Text-image-content .Bravo {
        font-size: 3.6rem; } }
    .Text-image-content-wrapper {
      display: flex;
      justify-content: center;
      align-items: center; }
  .Text-image-asset-container {
    position: relative;
    margin-left: auto;
    margin-right: 0;
    margin-top: 4rem; }
    @media screen and (min-width: 64em) {
      .Text-image-asset-container {
        padding: unset;
        margin-right: 0; } }
    @media screen and (max-width: 20em) {
      .Text-image-asset-container {
        width: 100%;
        height: 30rem; } }
    @media screen and (min-width: 20em) and (max-width: 37.5em) {
      .Text-image-asset-container {
        width: 32rem;
        height: 30rem;
        margin-right: auto; } }
    @media screen and (min-width: 37.5em) and (max-width: 48em) {
      .Text-image-asset-container {
        width: 40rem;
        height: 35rem;
        margin-right: auto; } }
    @media screen and (min-width: 48em) and (max-width: 64em) {
      .Text-image-asset-container {
        width: 50rem;
        height: 40rem;
        margin-right: auto; } }
    @media screen and (min-width: 64em) and (max-width: 80em) {
      .Text-image-asset-container {
        width: 40rem;
        height: 40rem; } }
    @media screen and (min-width: 80em) {
      .Text-image-asset-container {
        width: 55rem;
        height: 45rem; } }
    .Text-image-asset-container--left .Text-image-asset--flat {
      position: absolute;
      right: 0;
      bottom: 0;
      left: auto; }
    @media screen and (min-width: 64em) {
      .Text-image-asset-container--left {
        margin-right: auto;
        margin-left: 0; } }
    .Text-image-asset-container--right .Text-image-asset--square {
      position: absolute;
      top: 0;
      right: 0;
      left: auto; }
    .Text-image-asset-container--right .Text-image-asset--flat {
      position: absolute;
      right: auto;
      bottom: 0;
      left: 0; }
  .Text-image-asset--square picture, .Text-image-asset--flat picture {
    width: 100%;
    height: 100%; }
  .Text-image-asset--square img, .Text-image-asset--flat img {
    object-fit: cover;
    width: 100%;
    height: 100%; }
  .Text-image-asset--square {
    position: relative;
    overflow: hidden; }
    .Text-image-asset--square:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 100%; }
    .Text-image-asset--square > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    @media screen and (max-width: 20em) {
      .Text-image-asset--square {
        width: 25rem; } }
    @media screen and (min-width: 20em) and (max-width: 37.5em) {
      .Text-image-asset--square {
        width: 24rem; } }
    @media screen and (min-width: 37.5em) and (max-width: 48em) {
      .Text-image-asset--square {
        width: 28rem; } }
    @media screen and (min-width: 48em) and (max-width: 80em) {
      .Text-image-asset--square {
        width: 35rem; } }
    @media screen and (min-width: 80em) {
      .Text-image-asset--square {
        width: 40rem; } }
  .Text-image-asset--flat {
    position: relative;
    overflow: hidden; }
    .Text-image-asset--flat:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 75%; }
    .Text-image-asset--flat > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    @media screen and (max-width: 37.5em) {
      .Text-image-asset--flat {
        width: 16rem; } }
    @media screen and (min-width: 37.5em) and (max-width: 48em) {
      .Text-image-asset--flat {
        width: 22rem; } }
    @media screen and (min-width: 48em) and (max-width: 64em) {
      .Text-image-asset--flat {
        width: 24rem; } }
    @media screen and (min-width: 64em) {
      .Text-image-asset--flat {
        width: 28rem; } }
  .Text-image-asset--fill {
    width: 100vw;
    height: 100%;
    margin-left: -2rem;
    position: relative;
    top: 0; }
    .Text-image-asset--fill-right {
      right: 0; }
    .Text-image-asset--fill-left {
      left: 0; }
    @media screen and (min-width: 64em) {
      .Text-image-asset--fill {
        margin-left: 0;
        width: 50vw;
        position: absolute; } }
  .Text-image--background {
    background-color: #F7F7F7; }
  .Text-image--overlay {
    position: relative; }
    .Text-image--overlay::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 100;
      background: transparent; }
    @media screen and (min-width: 64em) {
      .Text-image--overlay-left::before {
        background: linear-gradient(270deg, #F7F7F7 51.01%, rgba(247, 247, 247, 0) 80.51%); } }
    @media screen and (min-width: 64em) {
      .Text-image--overlay-right::before {
        background: linear-gradient(90deg, #F7F7F7 51.01%, rgba(247, 247, 247, 0) 80.51%); } }
  .Text-image--fill .Text-image-content {
    padding: 7.5rem 0; }
    @media screen and (min-width: 48em) {
      .Text-image--fill .Text-image-content {
        padding: 10rem 0; } }
    @media screen and (min-width: 64em) {
      .Text-image--fill .Text-image-content {
        padding: 12.5rem 0; } }

.Title {
  margin-top: 7.5rem; }
  @media screen and (min-width: 48em) {
    .Title {
      margin-top: 10rem; } }
  @media screen and (min-width: 64em) {
    .Title {
      margin-top: 12.5rem; } }

.Video + .Block-spaced .Cta-content {
  margin-top: 0rem; }
  @media screen and (min-width: 48em) {
    .Video + .Block-spaced .Cta-content {
      margin-top: 0rem; } }
  @media screen and (min-width: 64em) {
    .Video + .Block-spaced .Cta-content {
      margin-top: 0rem; } }

.Video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 23rem 0;
  overflow: hidden;
  cursor: pointer; }
  .Video:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.85);
    opacity: 0.4;
    transition: opacity 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
  .Video:hover:after {
    opacity: 0.2; }

.Video-preview-object {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.Video-preview-image {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.Video-cta {
  position: relative;
  text-align: center;
  color: #ffffff;
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.8rem;
  letter-spacing: 1px;
  z-index: 1;
  display: block;
  margin: auto; }

.Video-cta-arrow {
  background: #ffffff;
  color: #BE9F56;
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 2rem; }

.Video-cta-icon {
  width: 2rem;
  transform: translateX(25%); }

.Video--small {
  padding: 0; }
  .Video--small .Video-cta {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%; }

.Grid-Block {
  background: #ffffff;
  background: linear-gradient(0deg, #ffffff 0%, #ffffff 55%, #F7F7F7 55%, #F7F7F7 100%);
  word-break: break-all; }
  .Grid-Block-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 5.5rem; }
    @media screen and (max-width: 48em) {
      .Grid-Block-header {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0 !important;
        padding-right: 0 !important; } }
    .Grid-Block-header-content {
      max-width: 523px;
      word-break: break-word; }
      @media screen and (max-width: 48em) {
        .Grid-Block-header-content {
          max-width: 100%; } }
      .Grid-Block-header-content .Bravo {
        word-break: initial; }
  .Grid-Block-items {
    display: flex;
    align-items: flex-start;
    flex-flow: row nowrap;
    overflow-x: hidden;
    margin-left: -1rem; }
    @media screen and (min-width: 86.25em) {
      .Grid-Block-items--space {
        justify-content: space-between; } }
  .Grid-Block-item {
    display: inline-flex;
    flex-direction: column;
    width: 23rem;
    min-width: 23rem;
    margin: 0 1rem; }
    .Grid-Block-item-image {
      position: relative;
      overflow: hidden;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05); }
      .Grid-Block-item-image:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 100%; }
      .Grid-Block-item-image > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
      .Grid-Block-item-image picture {
        width: 100%;
        height: 100%; }
      .Grid-Block-item-image img {
        object-fit: cover;
        width: 100%;
        height: 100%; }
    .Grid-Block-item-title {
      color: #BE9F56;
      margin-top: 3rem;
      margin-bottom: 0.5rem; }
      .Grid-Block-item-title--numbered {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.5rem;
        height: 3.5rem;
        color: #ffffff;
        font-size: 1.6rem;
        background-color: #BE9F56;
        border-radius: 50%;
        box-shadow: inset 0 0 0 1.5px #BE9F56, inset 0 0 0 4px #ffffff; }
        .Grid-Block-item-title--numbered span {
          display: block; }
    .Grid-Block-item p {
      word-break: break-word; }
  .Grid-Block-controls.is-hidden {
    display: none; }
    @media screen and (max-width: 86.25em) {
      .Grid-Block-controls.is-hidden {
        display: flex;
        justify-content: flex-end; } }
  .Grid-Block-controls.is-visible {
    display: flex !important;
    justify-content: flex-end; }

.Media-fill-object {
  max-height: 70rem; }

.Media-fill-image,
.Media-image {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center center; }

.Media-fill-image,
.Media-image {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center center; }

.Embed {
  max-width: 55rem;
  margin: auto; }

.Accordeon-category {
  padding: 2rem 3rem;
  background: #F7F7F7;
  margin-bottom: 0.5rem;
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.6rem;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: color 0.44s cubic-bezier(0.23, 1, 0.32, 1), background 0.44s cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer; }
  .Accordeon-category:last-child {
    margin-bottom: 2rem; }
  .Accordeon-category.is-active, .Accordeon-category:hover {
    color: #ffffff;
    background: #BE9F56; }
  .Accordeon-category .Icon {
    width: 1.7rem;
    transform: scale(-1, 1); }

.Accordeon-items {
  border-top: 0.1rem solid #BE9F56; }

.Accordeon-item {
  padding: 3rem 0;
  border-bottom: 0.1rem solid #BE9F56;
  cursor: pointer; }
  .Accordeon-item.is-hidden {
    display: none; }
  .Accordeon-item:hover .Accordeon-title {
    color: #BE9F56; }
  .Accordeon-item:hover .Accordeon-arrow {
    background: #BE9F56;
    color: #ffffff;
    transform: rotate(90deg); }
  .Accordeon-item.is-active .Accordeon-title {
    color: #BE9F56; }
  .Accordeon-item.is-active .Accordeon-arrow {
    background: #BE9F56;
    color: #ffffff;
    transform: rotate(90deg); }
  .Accordeon-item.is-active .Accordeon-content {
    max-height: 1000px;
    transition: max-height 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
    @media screen and (min-width: 48em) {
      .Accordeon-item.is-active .Accordeon-content {
        max-height: 500px; } }
    @media screen and (min-width: 64em) {
      .Accordeon-item.is-active .Accordeon-content {
        max-height: 300px; } }

.Accordeon-title {
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.6rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
  .Accordeon-title span {
    width: 90%; }

.Accordeon-arrow {
  width: 2.1rem;
  height: 2.1rem;
  margin-left: 1rem; }
  .Accordeon-arrow .Icon {
    width: 0.4rem; }

.Accordeon-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.44s cubic-bezier(0.23, 1, 0.32, 1); }

.Accordeon-content-inner {
  padding-top: 3rem; }
  .Accordeon-content-inner a {
    color: #BE9F56;
    text-decoration: underline; }

.Product {
  background-color: #F7F7F7; }
  .Product-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto; }
    .Product-title-image {
      max-width: 55rem;
      height: auto; }
  .Product-item {
    padding: 5rem;
    background-color: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    margin-bottom: 4rem; }
    .Product-item strong {
      font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
      color: #BE9F56;
      letter-spacing: 1px; }
    .Product-item:last-of-type {
      margin-bottom: 0; }
    .Product-item-number {
      max-width: 10rem;
      height: auto; }
      @media screen and (max-width: 64em) {
        .Product-item-number {
          padding-top: 4rem; } }
    .Product-item-images {
      margin-top: 2rem; }
    .Product-item-image-button {
      height: 100%;
      width: 100%; }
    .Product-item-image--big {
      display: flex;
      justify-content: center; }
      .Product-item-image--big img {
        max-height: 31rem;
        width: auto; }
    .Product-item-image--small {
      position: relative;
      overflow: hidden; }
      .Product-item-image--small:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 100%; }
      .Product-item-image--small > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
      .Product-item-image--small picture {
        width: 100%;
        height: 100%; }
      .Product-item-image--small img {
        object-fit: cover;
        width: 100%;
        height: 100%; }
    .Product-item-tag {
      position: absolute;
      top: 2rem;
      left: 0;
      display: inline;
      max-width: 10rem;
      padding: 1rem 2rem;
      font-family: Bebas Kai;
      font-size: 1.4rem;
      line-height: 1.5rem;
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
      transform: translate(-50%);
      background-color: rgba(0, 0, 0, 0.85); }
      @media screen and (max-width: 64em) {
        .Product-item-tag {
          transform: translate(2rem); } }
    .Product-item--has-tag {
      position: relative; }

/* Footer */
.Footer {
  background-color: rgba(0, 0, 0, 0.85); }
  .Footer-top {
    color: rgba(255, 255, 255, 0.65);
    padding-top: 8rem;
    padding-bottom: 6rem; }
  .Footer-bottom {
    font-size: 1.2rem;
    background: #ffffff;
    color: rgba(0, 0, 0, 0.35);
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .Footer-bottom-inner {
    margin-bottom: 2rem; }
    @media screen and (min-width: 37.5em) {
      .Footer-bottom-inner {
        display: flex;
        align-items: center;
        justify-content: space-between; } }

/* Print */
@media print {
  *,
  *::before,
  *::after,
  *::first-letter,
  p::first-line,
  div::first-line,
  blockquote::first-line,
  li::first-line {
    background: transparent !important;
    box-shadow: none !important;
    color: #000000 !important;
    letter-spacing: 0;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]::after {
    content: " (" attr(href) ")"; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: ""; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 0.1rem solid #9e9e9e;
    page-break-inside: avoid; }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  /**
     * General
     */
  html {
    font-family: sans-serif;
    font-size: 12pt; }
  .Container {
    max-width: none; }
  h1,
  h2,
  h3 {
    margin-bottom: 12pt;
    text-transform: none; }
  h1 {
    font-size: 24pt; }
  h2 {
    font-size: 18pt; }
  h3 {
    font-size: 14pt; } }

.Section {
  margin: 6rem 0; }
  @media screen and (min-width: 48em) {
    .Section {
      margin: 8rem 0; } }
  @media screen and (min-width: 64em) {
    .Section {
      margin: 10rem 0; } }
  .Section--light {
    background: #F7F7F7;
    padding: 6rem 0; }
    @media screen and (min-width: 48em) {
      .Section--light {
        padding: 8rem 0; } }
    @media screen and (min-width: 64em) {
      .Section--light {
        padding: 10rem 0; } }
  .Section--noSpacingTop {
    margin-top: 0 !important; }

.Read {
  background: #F7F7F7;
  padding-bottom: 8rem;
  margin-top: -8rem; }

.Read-spaced {
  margin: 2rem 0; }
  @media screen and (min-width: 64em) {
    .Read-spaced {
      margin: 4rem 0; } }

.Read-content {
  background: #ffffff;
  max-width: 92rem;
  padding: 22rem 2rem 4rem;
  position: relative;
  z-index: 0; }
  @media screen and (min-width: 48em) {
    .Read-content {
      padding: 22rem 4rem 8rem; } }
  @media screen and (min-width: 64em) {
    .Read-content {
      padding: 22rem 8rem 8rem; } }
  .Read-content--small {
    padding-top: 4rem; }
    @media screen and (min-width: 48em) {
      .Read-content--small {
        padding-top: 8rem; } }

.Read-editor ul {
  flex-wrap: wrap; }

.Read-editor ul li {
  flex-basis: 50%;
  margin-bottom: 0.5rem; }

.Read-editor h3 {
  margin-bottom: 1.5rem;
  font-size: 3.5rem;
  line-height: 1.2;
  text-transform: uppercase;
  color: #BE9F56;
  font-weight: 400; }

.Read-editor h4 {
  margin-bottom: 2rem; }

.Read-editor h6 {
  text-transform: none;
  color: rgba(0, 0, 0, 0.85); }

.Read-editor h3 + h6 {
  margin-top: -1rem; }

.Read-footer {
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 0.1rem solid rgba(190, 159, 86, 0.4); }

.Read-back {
  color: rgba(0, 0, 0, 0.85);
  opacity: 0.4;
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;
  background: #ffffff;
  width: 100%;
  padding-right: 2rem;
  transition: opacity 0.44s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: 1;
  top: 18rem; }
  @media screen and (min-width: 48em) {
    .Read-back {
      padding-right: 4rem; } }
  @media screen and (min-width: 64em) {
    .Read-back {
      position: absolute;
      background: none;
      top: 18rem;
      right: -4rem; } }
  .Read-back .Icon {
    width: 1.6rem;
    margin-right: 1rem; }
  .Read-back:hover, .Read-back:focus {
    opacity: 1; }

.Read-video {
  position: relative;
  cursor: pointer; }
  .Read-video:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.85);
    opacity: 0.4;
    transition: opacity 0.44s cubic-bezier(0.23, 1, 0.32, 1); }
  .Read-video:hover:after {
    opacity: 0.2; }

.Read-video-ratio {
  position: relative;
  overflow: hidden; }
  .Read-video-ratio:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 56.25%; }
  .Read-video-ratio > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.Read-video-cta {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  color: #ffffff;
  font-family: "Bebas Kai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.8rem;
  letter-spacing: 1px;
  z-index: 1;
  display: block;
  margin: auto; }

.Read-video-cta-arrow {
  background: #ffffff;
  color: #BE9F56;
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 2rem; }

.Read-video-cta-icon {
  width: 2rem;
  transform: translateX(25%); }

.Quote {
  color: #BE9F56;
  padding: 2rem 4rem;
  border-left: 3px solid #BE9F56; }

.Quote-content-quote {
  margin-bottom: 0;
  line-height: 100%; }
  .Quote-content-quote:last-child {
    text-align: right; }
