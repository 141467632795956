/* Slider */
.Slider {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-flow: row nowrap;
    overflow-x: hidden;
    margin-left: u(-$spacing-unit--xs);

    &--social {
        padding-bottom: u($spacing-unit--lg * 2);
        .Nav-list {
            justify-content: center;
        }
    }
}

.Slider-item {
    margin: 0 u($spacing-unit--xs);

    &--square {
        margin: 0 u($spacing-unit--sm);

        .Slider-figure-image {
            height: u(440);
        }
    }
}

.Slider-figure-image {
    max-width: unset;
    height: u(440);
    width: u(440);
    object-fit: cover;
    object-position: center center;
}
