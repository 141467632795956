
.Share,
.Share-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Share-list {
    padding: 0;
    list-style: none;
    margin: 0;
    margin-left: u($spacing-unit--sm);
    flex-wrap: nowrap;
}

.Share-label {
    font-family: $font-heading;
    letter-spacing: 1;
    text-transform: uppercase;
    color: $primary-color;
}

.Share-link {
    background: $primary-color;
    color: $white;
    border-radius: 50%;
    width: u(25);
    height: u(25);
    margin-left: u($spacing-unit--sm);
    display: flex;
    justify-content: center;
    align-items: center;

    .Icon {
        height: u(11);
        width: u(11);
    }

    .Icon--facebook {
        height: u(15);
        width: u(15);
    }
}
