/**
 * Display: Large
 */

@include mq($from: $viewport--lg) {
    .u-lg-block {
        display: block !important;
    }

    .u-lg-hidden {
        display: none !important;
    }


    // Completely remove from the flow but leave available to screen readers.

    .u-lg-hiddenVisually {
        border: 0 !important;
        clip: rect(1px, 1px, 1px, 1px) !important;
        height: 1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
    }

    .u-lg-inline {
        display: inline !important;
    }


    // 1. Fix for Firefox bug: an image styled `max-width:100%` within an
    // inline-block will display at its default size, and not limit its width to
    // 100% of an ancestral container.

    .u-lg-inlineBlock {
        display: inline-block !important;
        max-width: 100%; /* 1 */
    }

    .u-lg-table {
        display: table !important;
    }

    .u-lg-tableCell {
        display: table-cell !important;
    }

    .u-lg-tableRow {
        display: table-row !important;
    }
}