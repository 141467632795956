/* Paragraph */
p {
    margin-bottom: u($spacing-unit);

    a {
        color: $primary-color;
    }

    a:hover {
        color: $primary-color;
        text-decoration: underline;
    }
}

.#{$namespace}Lead {
    font-size: u($font-size * $paragraph-lead-modifier);
}
