.Text-image {
    min-height: u(650);
    display: flex;
    align-items: center;

    &-content {
        max-width: u(522);
        z-index: 101;

        @include mq($until: $viewport--lg) {
            max-width: 100%;
        }

        @include mq($until: $viewport--sm) {
            .Bravo {
                font-size: u(36px);
            }
        }

        &-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-asset {
        &-container {
            position: relative;
            margin-left: auto;
            margin-right: 0;
            margin-top: u($spacing-unit--lg);

            @include mq($from: $viewport--lg) {
                padding: unset;
                margin-right: 0;
            }

            @include mq($until: $viewport--xs) {
                width: 100%;
                height: u(300);
            }

            @include mq($from: $viewport--xs, $until: $viewport--sm) {
                width: u(320);
                height: u(300);
                margin-right: auto;
            }

            @include mq($from: $viewport--sm, $until: $viewport--md) {
                width: u(400);
                height: u(350);
                margin-right: auto;
            }

            @include mq($from: $viewport--md, $until: $viewport--lg) {
                width: u(500);
                height: u(400);
                margin-right: auto;
            }

            @include mq($from: $viewport--lg, $until: $viewport--xl) {
                width: u(400);
                height: u(400);
            }

            @include mq($from: $viewport--xl) {
                width: u(550);
                height: u(450);
            }

            &--left {
                .Text-image-asset--flat {
                    @include position(absolute, $left: auto, $right: 0, $bottom: 0);
                }

                @include mq($from: $viewport--lg) {
                    margin-right: auto;
                    margin-left: 0;
                }
            }

            &--right {
                .Text-image-asset--square {
                    @include position(absolute, $left: auto, $right: 0, $top: 0);
                }

                .Text-image-asset--flat {
                    @include position(absolute, $left: 0, $right: auto, $bottom: 0);
                }
            }
        }

        &--square,
        &--flat {
            picture {
                width: 100%;
                height: 100%;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        &--square {
            @include aspect-ratio(1, 1);

            @include mq($until: $viewport--xs) {
                width: u(250);
            }

            @include mq($from: $viewport--xs, $until: $viewport--sm) {
                width: u(240);
            }

            @include mq($from: $viewport--sm, $until: $viewport--md) {
                width: u(280);
            }

            @include mq($from: $viewport--md, $until: $viewport--xl) {
                width: u(350);
            }

            @include mq($from: $viewport--xl) {
                width: u(400);
            }
        }

        &--flat {
            @include aspect-ratio(4, 3);

            @include mq($until: $viewport--sm) {
                width: u(160);
            }

            @include mq($from: $viewport--sm, $until: $viewport--md) {
                width: u(220);
            }

            @include mq($from: $viewport--md, $until: $viewport--lg) {
                width: u(240);
            }

            @include mq($from: $viewport--lg) {
                width: u(280);
            }
        }

        &--fill {
            width: 100vw;
            height: 100%;
            margin-left: u(-$structure-responsive-gutter--l);
            position: relative;
            top: 0;

            &-right {
                right: 0;
            }

            &-left {
                left: 0;
            }

            @include mq($from: $viewport--lg) {
                margin-left: 0;
                width: 50vw;
                position: absolute;
            }
        }
    }

    &--background {
        background-color: $secondary-color;
    }

    &--overlay {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 100;
            background: transparent;
        }

        &-left {
            &::before {
                @include mq($from: $viewport--lg) {
                    background: linear-gradient(270deg, $secondary-color 51.01%, rgba(247, 247, 247, 0) 80.51%);
                }
            }
        }

        &-right {
            &::before {
                @include mq($from: $viewport--lg) {
                    background: linear-gradient(90deg, $secondary-color 51.01%, rgba(247, 247, 247, 0) 80.51%);
                }
            }
        }
    }


    &--fill {
        .Text-image-content {
            padding: u(75) 0;

            @include mq($from: $viewport--md) {
                padding: u(100) 0;
            }

            @include mq($from: $viewport--lg) {
                padding: u(125) 0;
            }
        }
    }
}
