.Cta-content {
    background: $primary-color;
    padding: u($spacing-unit--lg) u($spacing-unit);
    color: $white;
    position: relative;
    height: 100%;

    @include mq($from: $viewport--md) {
        padding: u($spacing-unit--lg) u($spacing-unit * 4);
    }

    @include mq($from: $viewport--lg) {
        padding: u($spacing-unit--xl) u($spacing-unit * 6);
    }

    &--reversed {
        .Cta-logo {
            left: 0;
            right: auto;
            transform: translateX(-40%);
        }
    }

    &--full {
        background: rgba($primary-color, 0.85);
    }
}

/*
    IMAGE
*/
// Background
.Cta-bg-object {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;

    & + .Cta-content {
        margin-top: u(-60);

        @include mq($from: $viewport--md) {
            margin-top: u(-80);
        }
    
        @include mq($from: $viewport--lg) {
            margin-top: u(-100);
        }
    }
}

// Side
.Cta-figure-object {
    width: 100%;
    height: 100%;
    position: relative;
}

.Cta-figure-image,
.Cta-bg-image {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center center;
}

.Cta-figure-image {
    @include mq($from: $viewport--md) {
        position: absolute;
        top: 0;
        left: 0;
    }
}

/*
    LOGO
*/
.Cta-logo {
    opacity: 0.1;
    width: u(175);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    transform: translateX(40%);

    path {
        fill: $white;
    }

    path:first-child {
        fill: none;
    }
}

.Cta-logo-small {
    width: u(32);
    margin-bottom: u($spacing-unit)
}
