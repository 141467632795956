.cookie-though {
    padding: u($spacing-unit);
    z-index: 499;
    line-height: 1.5rem;

    @include mq($until: $viewport--sm) {
        padding: u(0);
    }

    --ct-primary-400: #000 !important;
    --ct-primary-300: #BE9F56 !important;
    --ct-primary-200: #dfbd6fce;
    --ct-primary-100: #F7F7F7;

    --ct-text: #6b7280;

    --ct-subtitle: var(--ct-primary-400);
    --ct-title: var(--ct-primary-400);

    --ct-cookie: var(--ct-primary-400);

    --ct-policy: #fff;
    --ct-policy-enabled: #f0dbaace;
    --ct-policy-text: var(--ct-primary-400);
    --ct-policy-text-enabled: #000;
    --ct-policy-essential: var(--ct-primary-200);

    --ct-slider-enabled-primary: var(--ct-primary-200);
    --ct-slider-enabled-secondary: var(--ct-primary-300);

    --ct-primary-font: "Roboto", sans-serif !important;
    --ct-secondary-font: 'Roboto', sans-serif i !important;

    --ct-text-font-size: 11px;
    --ct-button-font-size: 11px;
}