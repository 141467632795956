/* Blocks */
.Block-spaced {
    margin: u(60) 0;

    @include mq($from: $viewport--md) {
        margin: u(80) 0;
    }

    @include mq($from: $viewport--lg) {
        margin: u(100) 0;
    }
}

.Block-spaced--inside {
    padding: u(60) 0;

    @include mq($from: $viewport--md) {
        padding: u(80) 0;
    }

    @include mq($from: $viewport--lg) {
        padding: u(100) 0;
    }
}

@import '7-theme/blocks/textImage';
@import '7-theme/blocks/title';
@import '7-theme/blocks/video';
@import '7-theme/blocks/grid';
@import '7-theme/blocks/media';
@import '7-theme/blocks/embed';
@import '7-theme/blocks/accordeon';
@import '7-theme/blocks/product';
