.Video + .Block-spaced .Cta-content {
    margin-top: u(0);

    @include mq($from: $viewport--md) {
        margin-top: u(0);
    }

    @include mq($from: $viewport--lg) {
        margin-top: u(0);
    }
}

.Video {
    position: relative;
    display: flex;
    justify-content: center;;
    align-items: center;
    padding: u($spacing-unit * 11.5) 0;
    overflow: hidden;
    cursor: pointer;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: $quaternary-color;
        opacity: 0.4;
        transition: opacity $global-transition;
    }

    &:hover {
        &:after {
            opacity: 0.2;
        }
    }
}

.Video-preview-object {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.Video-preview-image {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Video-cta {
    position: relative;
    text-align: center;
    color: $white;
    font-family: $font-nav;
    font-size: u(18);
    letter-spacing: 1px;
    z-index: 1;
    display: block;
    margin: auto;
}

.Video-cta-arrow {
    background: $white;
    color: $primary-color;
    width: u(65);
    height: u(65);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: u($spacing-unit);
}

.Video-cta-icon {
    width: u(20);
    transform: translateX(25%);
}

.Video--small {
    padding: 0;

    .Video-cta {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
    }
}
