.Link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: u($spacing-unit--xs) 0;
    transition: color $global-transition;

    &--primary {
        color: $primary-color;
        transition: opacity $global-transition;

        &:hover {
            opacity: 0.7;
        }
    }

    &--tertiary {
        color: $tertiary-color;

        &:hover {
            color: $white;
        }
    }

    &--small {
        font-size: u(12);
        line-height: u(25);
        letter-spacing: 0.5px;
        font-weight: 300;
    }

    &--none {
        text-decoration: none;
        color: inherit;

        &:hover {
            color: $primary-color;
        }
    }

    &--start {
        align-items: flex-start;

        .Icon {
            transform: translateY(25%);
        }
    }

    .Icon {
        margin-right: u($spacing-unit--sm);
    }
}
