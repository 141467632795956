.Read-video {
    position: relative;
    cursor: pointer;

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: $quaternary-color;
        opacity: 0.4;
        transition: opacity $global-transition;
    }

    &:hover {
        &:after {
            opacity: 0.2;
        }
    }
}

.Read-video-ratio {
    @include aspect-ratio(16, 9);
}

.Read-video-cta {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    color: $white;
    font-family: $font-nav;
    font-size: u(18);
    letter-spacing: 1px;
    z-index: 1;
    display: block;
    margin: auto;
}

.Read-video-cta-arrow {
    background: $white;
    color: $primary-color;
    width: u(65);
    height: u(65);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: u($spacing-unit);
}

.Read-video-cta-icon {
    width: u(20);
    transform: translateX(25%);
}
