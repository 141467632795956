/* Navigation */
.Navigation {

    &-toggle {
        display: block;
        position: relative;
        z-index: 5;
    }

    &-body {
        @include position(fixed, 0, 0, 0, 0, 1);
        left: auto;
        width: 100%;
        min-height: 100vh;
        background-color: $black;
        padding: 6.5rem u($spacing-unit--lg);
        transform: translateX(0);
        transition:
            transform $global-transition,
            visibility $global-transition;

        @include mq($from: $viewport--md) {
            width: u(340);
            background-color: $header-bg-color;
        }

        @include mq($from: $container-width) {
            width: calc(#{u(340)} + ((100vw - #{u($container-width)}) / 2));
            padding-right: calc(((100vw - #{u($viewport--xxl)}) / 2) - (#{if($structure-responsive-gutter--r > 0 and $structure-gutter--r != $structure-responsive-gutter--r, u($structure-gutter--r), null)} + #{u($spacing-unit)}));
        }

        &--inner {
            flex-direction: column;
            display: flex;
            justify-content: space-between;
            height: calc(100% + 1px);
        }

        html:not(.show-nav) & {
            transform: translateX(100%);
            visibility: hidden;
        }
    }
}

/* Types */
@import '6-components/navigation/main';
@import '6-components/navigation/lang';
@import '6-components/navigation/footer';
@import '6-components/navigation/side';
@import '6-components/navigation/social';
@import '6-components/navigation/pagination';
