.Nav--pagination {
    text-align: center;
    margin-top: u($spacing-unit--lg);

    .Nav-list {
        justify-content: center;
        align-items: center;
    }

    .Nav-link {
        border-radius: 50%;
        width: u(45);
        height: u(45);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color $global-transition, background-color $global-transition;
        margin: 0;

        &.is-active,
        &:hover,
        &:focus {
            background-color: $primary-color;
            color: $white;
        }
    }

    .Nav-link--prev,
    .Nav-link--next {
        color: $primary-color;

        &.is-active,
        &:hover,
        &:focus {
            background-color: unset;
            color: $quaternary-color;
        }

        .Icon {
            width: u(17);
        }
    }

    .Nav-link--next {
        transform: scale(-1, 1);
    }
}
