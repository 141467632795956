.#{$modal-namespace}Modal {
    @include transition(#{opacity, visibility});
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 500;

    &,
    &:before {
        @include position(fixed, 0, 0, 0, 0);
    }

    &::before {
        background-color: $quaternary-color;
        content: '';
        z-index: -1;
    }

    &:not(.is-active) {
        opacity: 0;
        visibility: hidden;
    }

    // Inner
    &-inner {
        display: flex;
        align-items: center;
        min-height: 100%;

        @include mq($until: $viewport--md) {
            padding: 0 $spacing-unit;
        }

        &-body {
            background-color: $white;
            margin: u($modal-margin--t, $modal-margin--r, $modal-margin--b, $modal-margin--b);
            width: 100%;
            position: relative;
            padding: u($spacing-unit * 3);

            @include mq($until: $viewport--md) {
                margin: 0 !important;
            }

            @include mq($from: $viewport--sm) {
                // margin: u($spacing-unit);
                max-width: u($modal-width);
                width: 100%;
            }

            @include mq($from: $viewport--md) {
                margin: u($spacing-unit--lg) u($spacing-unit--xl);
            }

            @include mq($from: $modal-width) {
                margin: u($spacing-unit--lg) auto;
            }
        }
    }

    // Close
    &-close {
        cursor: pointer;
        line-height: 1;
        margin-left: auto;
        margin-right: 0;
        text-indent: -1000px;
        overflow: hidden;
        position: absolute;
        top: u($spacing-unit--sm);
        right: u($spacing-unit--sm);
        transform: rotate(45deg);
        width: u(25);
        height: u(25);

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background: $primary-color;
            transition: transform $global-transition;
        }

        &:after {
            transform: rotate(90deg);
        }

        @include mq($from: $viewport--md) {
            top: 0;
            right: 0;
            transform: translate(150%, -20%) rotate(45deg);

            &:before,
            &:after {
                background: $white;
            }
        }

        &:hover {
            &:before {
                transform: rotate(-45deg);
            }
            &:after {
                transform: rotate(135deg);
            }
        }

        html:not(.is-tabbing) &:focus {
            outline: 0;
        }
    }

    .Form-item--input {
        margin-bottom: u($spacing-unit * 1.5);
    }
}

.Modal-video {
    .Modal-inner-body {
        background: none;
        padding: 0;
        margin: 0 auto;
        max-width: u($container-width);
    }

    .Modal-close {
        @include mq($until: $viewport--md) {
            transform: translate(u($spacing-unit--sm), -150%) rotate(45deg);

            &:before,
            &:after {
                background: $white;
            }
        }
    }
}

.Modal-product {
    .Modal-inner-body {
        margin: 0 auto;
        padding: 0;
        width: auto;
        height: auto;
        max-height: 60%;
    }
}

.Modal-legal {
    display: none;
    
    h2 {
        color: $primary-color;
        line-height: u(50);
    }
}
