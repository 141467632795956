.Navigation-side {

    .Logo-object {
        max-width: u(36)
    }

    .Nav--lang {
        .Nav-link {
            color: rgba($white, 0.25);

            &.is-active {
                color: rgba($white, 0.5);
            }

            &:not(.is-active):hover {
                color: rgba($white, 0.5);
            }
        }
    }

    .Nav--socialMedia {
        .Nav-list {
            @include mq($from: $viewport--md) {
                justify-content: flex-end;
            }
        }

        .Nav-link {
            background: none;
            width: u(17);
            height: u(17);
            color: $tertiary-color;

            &:hover {
                background: none;
                color: $primary-color;
            }
        }

        .Icon {
            max-width: u(17);
            max-height: u(17);
        }
    }

    .Navigation-body {
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }
    }
}
    


.Nav--side-main {
    font-size: u(22);
    line-height: 100%;
    display: block;
    margin-top: u($spacing-unit);

    @include mq($from: $viewport--md) {
        text-align: right;
    }

    .Nav-list {
        display: block;
    }

    // Link
    .Nav-link {
        display: inline-block;
        position: relative;
        color: $tertiary-color;
        padding: u($spacing-unit * 0.75) u($spacing-unit--sm);
        transition: transform $global-transition;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: u(25);
            height: u(1);
            background: $primary-color;
            transform-origin: right center;
            transform: translate(-100%, 0) scaleX(0);
            transition: transform $global-transition;
        }

        &.is-active,
        &:active,
        &:focus,
        &:hover {
            color: $primary-color;
            transform: translate(u(25 + $spacing-unit--sm), 0);

            &:before {
                transform: translate(-100%, 0) scaleX(1);
            }

            @include mq($from: $viewport--md) {
                transform: none;
            }
        }
    }
}

.Nav--sub {
    line-height: 100%;
    display: block;
    border-top: 0.5px solid rgba($white, 0.25);
    border-bottom: 0.5px solid rgba($white, 0.25);
    margin-top: u($spacing-unit--sm);
    margin-bottom: u($spacing-unit--sm);
    padding: u($spacing-unit--sm) 0;

    @include mq($from: $viewport--md) {
        text-align: right;
    }

    .Nav-list {
        display: block;
    }

    // Link
    .Nav-link {
        display: inline-block;
        position: relative;
        color: $tertiary-color;
        padding: u($spacing-unit--sm);
        transition: transform $global-transition;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: u(25);
            height: u(1);
            background: $primary-color;
            transform-origin: right center;
            transform: translate(-100%, 0) scaleX(0);
            transition: transform $global-transition;
        }

        &.is-active,
        &:active,
        &:focus,
        &:hover {
            color: $primary-color;
            transform: translate(u(25 + $spacing-unit--sm), 0);
            
            &:before {
                transform: translate(-100%, 0) scaleX(1);
            }

            @include mq($from: $viewport--md) {
                transform: none;
            }
        }
    }
}
