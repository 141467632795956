/**
 *  MEDIUM VERSION
 */
.Preview-list {
    margin: u(75) 0;

    @include mq($from: $viewport--md) {
        margin: u(100) 0;
    }

    @include mq($from: $viewport--lg) {
        margin: u(125) 0;
    }
}

.Preview {
    background: $secondary-color;
    height: 100%;

    &--light {
        background: $white;

        .Preview-header {
            background: $white;
        }
    }
}

.Preview-header {
    padding: u($spacing-unit--lg) u($spacing-unit);
    background: $secondary-color;
    transition: transform $global-transition;
}

.Preview-figure-object {
    height: u(195);
}

.Preview-figure-image {
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
}

.fonts-loaded .Preview-title {
    font-family: $font-body;
    font-weight: 500;
    font-size: u(16);
    line-height: u(20);
    letter-spacing: 1px;
    margin: 0;
    text-transform: none;
    color: $quaternary-color;
}

.Preview-link {
    color: $quaternary-color;

    &:hover,
    &:focus {
        color: $quaternary-color;

        .Preview-header {
            transform: translateY(u(-$spacing-unit--sm));
            color: $quaternary-color;
        }
    }
}

/**
 *  SMALL VERSION
 */
.Preview-small {
    background: $white;
    margin-bottom: u($spacing-unit);
}

.Preview-category {
    font-family: $font-nav;
    color: $primary-color;
    letter-spacing: 1px;
}

.Preview-button {
    color: $primary-color;
    font-family: $font-nav;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .Icon {
        width: u(4);
    }

    .Icon-wrapper {
        border: u(1) solid rgba($primary-color, 0.35);
        border-radius: 50%;
        width: u(26);
        height: u(26);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: u($spacing-unit--sm);
    }
}

.Preview-small-content {
    padding: 0 u($spacing-unit);
}

.Preview-small-title {
    font-size: u(16);
    font-weight: 500;
    color: $quaternary-color;
}

.Preview-small-figure {
    @include aspect-ratio(100px, 100px);
    width: u(110);
}

.Preview-small-figure-object {
    height: 100%;
}

.Preview-small-figure-image {
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
}

.fonts-loaded .Preview-small-title {
    font-family: $font-body;
}

/**
 *  LARGE VERSION
 */
 .Preview-large-content {
    padding: u($spacing-unit--lg);
}

.Preview-large-figure-object {
    height: 100%;
}

.Preview-large-figure-image {
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
}
