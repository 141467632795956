/* Footer */
.Footer {
    background-color: $footer-bg-color;

    // Top
    &-top {
        color: $tertiary-color;
        padding-top: u($spacing-unit * 4);
        padding-bottom: u($spacing-unit * 3);
    }

    // Bottom
    &-bottom {
        font-size: u(12);
        background: $white;
        color: rgba($black, 0.35);
        padding-top: u($spacing-unit);
        padding-bottom: u($spacing-unit);
    }

    &-bottom-inner {
        margin-bottom: u($spacing-unit);

        @include mq($from: $viewport--sm) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
