.Hero {
    position: relative;
    padding-top: u($structure-header-height + $spacing-unit--xl);
    padding-bottom: u($spacing-unit--xl);
    color: $white;

    &--beer {
        padding-bottom: u($spacing-unit);
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $header-bg-color;
    }
}

.Hero + .Text-image {
    @include mq($until: $viewport--lg - 1) {
        margin-top: u($spacing-unit--xl * 1.5);
    }
}

.Hero-header {
    margin-bottom: u($spacing-unit);
    max-width: u(660);

    @include mq($from: $viewport--md) {
        padding-left: u(($container-width-lg - $container-width) / 2);
    }

    h3 {
        @include mq($from: $viewport--xl) {
            @include font-size($heading-size-1, $heading-spacing-1);
        }

        @include mq($from: $viewport--lg, $until: $viewport--xl - 1) {
            @include font-size($heading-size-2, $heading-spacing-2);
        }

        @include font-size($heading-size-5, $heading-spacing-5);
    }
}

.Hero-figure-object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.Hero-figure-image {
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center;
}

.Hero-content {
    position: relative;
    z-index: 5;

    &--small {
        max-width: u(600);
        margin: auto;
    }
}

.Hero-description {
    h3 {
        font-family: $font-body;
        font-size: u(16);
        line-height: u(29);
        text-transform: uppercase;
        color: $primary-color;
    }
}

.Hero-list {
    border-top: u(1) solid rgba($primary-color, 0.4);
    padding-top: u($spacing-unit);
}

.Hero-list-item {
    display: flex;
}

.Hero-list-label {
    font-family: $font-nav;
    color: $primary-color;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.Hero-logo {
    opacity: 0.05;
    width: u(350);
    position: absolute;
    top: u($structure-header-height + ($spacing-unit * 1.5));
    left: u(-$spacing-unit--lg);

    path {
        fill: $white;
    }

    path:first-child {
        fill: none;
    }
}

.Hero-pointer {
    position: absolute;
    z-index: 1;
    color: $white;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin: auto;
    text-transform: uppercase;
    font-family: $font-nav;
    padding-bottom: u(40);
    animation: float 5s ease-in-out infinite;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
        width: 1px;
        height: 50px;
        background: $white;
        transform: translateY(50%);
    }

    @include mq($from: $viewport--sm) {
        padding-bottom: u(80);

        &:after {
            height: 100px;
        }
    }
}

.Hero-figure-side-object {
    margin-bottom: u(-$spacing-unit--xl * 2);
    position: relative;
    z-index: 10;
    height: u(400);
}

.Hero-figure-side-image {
    object-fit: cover;
    object-position: center center;
    max-width: unset;
    width: 100%;
    height: 100%;
}

.Hero-figure-beer-object {
    position: relative;
    z-index: 10;
    max-width: 70%;
    margin: auto auto u(40) auto;

    @include mq($from: $viewport--md) {
        max-width: u(275);
    }

    @include mq($from: $viewport--lg) {
        margin-bottom: u(-$spacing-unit--xl - $spacing-unit--lg);
        height: u(700);
        transform: translate(0, u(55));
        max-width: unset;
    }

    @include mq($from: 1300px) {
        transform: translate(u(-$spacing-unit--xl), 0);
    }
}

.Hero-figure-beer-image {
    height: 100%;
    object-fit: contain;
}

.Hero-awards {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: u($spacing-unit--lg);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Hero-awards-item {
    flex-basis: u(45);
    margin-right: u($spacing-unit--lg);
    transform: scale(1.3);
}

.Hero-category,
.Hero-subtitle {
    font-family: $font-nav;
    color: $primary-color;
    letter-spacing: 1px;
    text-transform: uppercase;
}

// Extended
.Hero--extended {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: u($structure-header-height);

    &:before,
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    &:before {
        background: linear-gradient(90deg, rgba($black, 0.85) 0%, rgba($black, 0.85) 0.01%, rgba($black, 0) 45.31%);
    }

    &:after {
        background: linear-gradient(180deg, rgba($black, 0.85) 0%, rgba($black, 0.85) 0%, rgba($black, 0) 50%);
    }
}
