.Category-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.Category-item {
   margin: u($spacing-unit) u($spacing-unit--xs);
}

.Category-link {
    font-size: u(16);
    font-family: $font-nav;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    text-align: none;
    color: $primary-color;
    border: u(1) solid $primary-color;
    padding: u($spacing-unit--sm) u($spacing-unit);
    border-radius: u($spacing-unit--lg);
    transition: color $global-transition, background-color $global-transition;

    &:hover,
    &.is-active {
        color: $white;
        background-color: $primary-color;
    }
}
