.Quote {
    color: $primary-color;
    padding: u($spacing-unit) u($spacing-unit--lg);
    border-left: 3px solid $primary-color;
}

.Quote-content-quote {
    margin-bottom: 0;
    line-height: 100%;

    &:last-child {
        text-align: right;
    }
}
