/* Fields: field */
%Form-field--reset {
    @include font-smoothing($font-smoothing);
    appearance: none;
    border: px($form-element-border-width) solid rgba($form-element-border-color, 0.4);
    color: $form-element-color;
    vertical-align: middle;
    width: 100%;
    background: none;
    font-weight: 300;
    color: $quaternary-color;
    border-radius: 0px;
    -webkit-border-radius: 0px;

    // Error
    .#{$form-namespace}Form-item.is-error & {
        border-color: $form-error-color;
    }

    // Success
    .#{$form-namespace}Form-item.is-success & {
        border-color: $form-success-color;
    }
}

%Form-field--shadow {
    box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow);

    // UI state
    &:focus {
        border-color: $form-element-border-color--focus;
        box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow) if(is-false($form-element-box-shadow) or is-false($form-element-box-shadow--focus), null, #{','}) if(is-false($form-element-box-shadow--focus), null, $form-element-box-shadow--focus);
        outline: 0;
        z-index: 1;

        // Error
        .#{$form-namespace}Form-item.is-error & {
            box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow) if(is-false($form-element-box-shadow) or is-false($form-element-box-shadow--focus), null, #{','}) if(is-false($form-element-box-shadow--focus), null, $form-error-element-box-shadow);
        }

        // Success
        .#{$form-namespace}Form-item.is-success & {
            box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow) if(is-false($form-element-box-shadow) or is-false($form-element-box-shadow--focus), null, #{','}) if(is-false($form-element-box-shadow--focus), null, $form-success-element-box-shadow);
        }
    }
}

.#{$form-namespace}Form-field {
    font-family: inherit;
    position: relative;
}

// Indicator for file, radio & checkbox elements
.#{$form-namespace}Form-indicator {
    user-select: none;
}

// Sizes
.#{$form-namespace}Form-field--xs { max-width: u($form-element--xs) !important; }
.#{$form-namespace}Form-field--sm { max-width: u($form-element--sm) !important; }
.#{$form-namespace}Form-field--md { max-width: u($form-element--md) !important; }
.#{$form-namespace}Form-field--lg { max-width: u($form-element--lg) !important; }
.#{$form-namespace}Form-field--xl { max-width: u($form-element--xl) !important; }
.#{$form-namespace}Form-field--full { max-width: none !important; }

.Form-label {
    font-weight: 300;
    color: $primary-color;
    margin-left: u($form-element-padding--l - $spacing-unit--xs);
    padding: 0 u($spacing-unit--xs);
    transition: transform $global-transition;
    background: $white;
    display: inline-block;
    position: relative;
    z-index: 1;
}

.Form-item--input .Form-controls {
    margin-top: u(-50);
    margin-bottom: u($spacing-unit--md * 2);
}

.Form-item {
    &.is-active {
        .Form-label {
            transform: translate3d(0, u(-25), 0);
        }
    }
}

.Form-button {
    position: relative;

    .Icon {
        position: absolute;
        top: 0;
        left: u($button-padding--l);
        bottom: 0;
        margin: auto;
        width: u(6);
        color: $white;
    }

    .Button {
        padding-left: u($spacing-unit--lg);
    }
}
