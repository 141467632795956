/* Logo */
.Logo {
    flex-shrink: 0;

    svg {
        display: block;
        width: u(140px);

        @include mq($from: $viewport--lg) {
            width: u(183px);
        }
    }
}
