/* Heading */
h1, .#{$namespace}Alfa,
h2, .#{$namespace}Bravo,
h3, .#{$namespace}Charlie,
h4, .#{$namespace}Delta,
h5, .#{$namespace}Echo,
h6, .#{$namespace}Foxtrot {
    font-family: serif;

    .fonts-loaded & {
        font-family: $font-heading;
    }

    small {
        font-weight: normal;
    }
}


h1, .#{$namespace}Alfa,
h2, .#{$namespace}Bravo,
h3, .#{$namespace}Charlie {
    text-transform: uppercase;
    font-weight: 700;
}

h4, .#{$namespace}Delta,
h5, .#{$namespace}Echo,
h6, .#{$namespace}Foxtrot {
    color: $primary-color;
}

h1, .#{$namespace}Alfa {
    @include font-size($heading-size-3, $heading-spacing-3);
    line-height: u(50);

    @include mq($from: $viewport--sm) {
        @include font-size($heading-size-1, $heading-spacing-1);
        line-height: u(70);
    }
}

h2, .#{$namespace}Bravo {
    @include font-size($heading-size-2, $heading-spacing-2);
    line-height: u(50);

    @include mq($until: $viewport--md) {
        font-size: u(40px);
    }
}

h3, .#{$namespace}Charlie {
    @include font-size($heading-size-3, $heading-spacing-3);
}

h4, .#{$namespace}Delta {
    @include font-size($heading-size-4, $heading-spacing-4);
    text-transform: uppercase;
    margin-bottom: u($spacing-unit * 1.5);
    font-weight: 700;
}

h5, .#{$namespace}Echo {
    @include font-size($heading-size-5, $heading-spacing-5);
    line-height: u(35);
    text-transform: none;
    font-weight: 400;
}

h6, .#{$namespace}Foxtrot {
    @include font-size($heading-size-6, $heading-spacing-6);
    text-transform: uppercase;
}


// A series of classes for setting massive type; for use in heroes, mastheads,
// promos, etc.

.#{$namespace}Giga {
    @include font-size($heading-size-giga, $heading-spacing-giga);
}

.#{$namespace}Mega {
    @include font-size($heading-size-mega, $heading-spacing-mega);
}

.#{$namespace}Kilo {
    @include font-size($heading-size-kilo, $heading-spacing-kilo);
}

.#{$namespace}Milli {
    @include font-size($heading-size-milli, $heading-spacing-milli);
}

.#{$namespace}Micro {
    @include font-size($heading-size-micro, $heading-spacing-micro);
}
