/* Colors: Theme */

// Lay-out
$primary-color:                     #BE9F56 !default;
$secondary-color:                   #F7F7F7 !default;

$tertiary-color:                    rgba($white, 0.65) !default;
$quaternary-color:                  rgba($black, 0.85) !default;

$text-color:                        $black !default;
$bg-color:                          $white !default;
$border-color:                      $cc-grey !default;
$image-color:                       $grey--200 !default;

$header-bg-color:                   $quaternary-color !default;
$footer-bg-color:                   $quaternary-color !default;


// Modifiers
$theme-modifiers: (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "tertiary": $tertiary-color,
    "success": $green,
    "info": $blue,
    "warning": $orange,
    "danger": $red,
) !default;
