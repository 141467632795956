// Side
.Accordeon-category {
    padding: u($spacing-unit) u($spacing-unit * 1.5);
    background: $secondary-color;
    margin-bottom: u($spacing-unit--xs);
    font-family: $font-nav;
    font-size: u(16);
    letter-spacing: 1px;
    color: $quaternary-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: color $global-transition, background $global-transition;
    cursor: pointer;

    &:last-child {
        margin-bottom: u($spacing-unit);
    }

    &.is-active,
    &:hover {
        color: $white;
        background: $primary-color;
    }

    .Icon {
        width: u(17);
        transform: scale(-1, 1);
    }
}

// Item
.Accordeon-items {
    border-top: u(1) solid $primary-color;
}

.Accordeon-item {
    padding: u($spacing-unit * 1.5) 0;
    border-bottom: u(1) solid $primary-color;
    cursor: pointer;

    &.is-hidden {
        display: none;
    }

    &:hover {
        .Accordeon-title {
            color: $primary-color;
        }

        .Accordeon-arrow {
            background: $primary-color;
            color: $white;
            transform: rotate(90deg);
        }
    }

    &.is-active {
        .Accordeon-title {
            color: $primary-color;
        }

        .Accordeon-arrow {
            background: $primary-color;
            color: $white;
            transform: rotate(90deg);
        }

        .Accordeon-content {
            max-height: 1000px;
            transition: max-height $global-transition;

            @include mq($from: $viewport--md) {
                max-height: 500px;
            }

            @include mq($from: $viewport--lg) {
                max-height: 300px;
            }
        }
    }
}

.Accordeon-title {
    font-family: $font-nav;
    font-size: u(16);
    letter-spacing: 1px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: color $global-transition;

    span {
        width: 90%;
    }
}

.Accordeon-arrow {
    width: u(21);
    height: u(21);
    margin-left: u($spacing-unit--sm);

    .Icon {
        width: u(4);
    }
}

.Accordeon-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height $global-transition;
}

.Accordeon-content-inner {
    padding-top: u($spacing-unit * 1.5);

    a {
        color: $primary-color;
        text-decoration: underline;
    }
}
