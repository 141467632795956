.Nav--socialMedia {
    .Nav-list {
        margin-left: u(-$spacing-unit--xs);
    }

    .Nav-link {
        width: u(40);
        height: u(40);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: $white;
        background: $primary-color;
        margin: u($spacing-unit--xs);
        transition: background $global-transition, color $global-transition;

        &:hover,
        &:focus,
        &:active {
            background: $secondary-color;
            color: $quaternary-color;
        }

        @include mq($from: $viewport--md) {
            width: u(25);
            height: u(25);
        }
    }

    .Icon {
        max-width: u(24);
        max-height: u(24);
        width: 100%;

        @include mq($from: $viewport--md) {
            max-width: u(15);
            max-height: u(14);
        }
    }
}
