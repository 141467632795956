/* Settings: Fonts */
@font-face {
    font-family: 'Roboto';
    src:    url('../fonts/Roboto-Light.woff') format('woff'),
            url('../fonts/Roboto-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src:    url('../fonts/Roboto-Medium.woff') format('woff'),
            url('../fonts/Roboto-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Bebas Kai';
    src:    url('../fonts/BebasKai.woff') format('woff'),
            url('../fonts/BebasKai.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Garamond';
    src:    url('../fonts/GaramondBold.woff') format('woff'),
            url('../fonts/GaramondBold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Garamond';
    src:    url('../fonts/GaramondBoldItalic.woff') format('woff'),
            url('../fonts/GaramondBoldItalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Garamond';
    src:    url('../fonts/GaramondRegular.woff') format('woff'),
            url('../fonts/GaramondRegular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

// Default
$font-sans:                             -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !default;
$font-mono:                             SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

// Custom
$font-body:                      "Roboto", $font-sans !default; // 400, 700
$font-heading:                   "Garamond", serif !default; // 400, 700
$font-nav:                       "Bebas Kai", $font-sans !default; // 400, 700
