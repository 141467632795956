/* Main */
html {
    background-color: $bg-color;
    color: $text-color;
    font-family: $font-sans;
    height: 100%;

    &.fonts-loaded {
        font-family: $font-body;
        font-weight: 300;
    }

    &.hide-scroll {
        overflow: hidden;
    }
}

body {
    font-size: u(15);
    line-height: u(25);
    letter-spacing: 0.02em;
    color: $quaternary-color;
    height: 100%;
}

em {
    font-style: normal;
    color: $primary-color;
}

.Main-content-inner {
    &.Contact {
        margin: u(140) 0 u(100) 0;
    }

    @include mq($until: $viewport--lg) {
        margin: u($spacing-unit--xl * 1.5) 0;
    }
}
