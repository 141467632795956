.Grid-Block {
    background: $white;
    background: linear-gradient(0deg, $white 0%, $white 55%, $secondary-color 55%, $secondary-color 100%);
    word-break: break-all;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: u($spacing-unit * 2.75);

        @include mq($until: $viewport--md) {
            flex-direction: column;
            align-items: flex-start;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        &-content {
            max-width: 523px;
            word-break: break-word;

            @include mq($until: $viewport--md) {
                max-width: 100%;
            }

            .Bravo {
                word-break: initial;
            }
        }
    }

    // Items
    &-items {
        display: flex;
        align-items: flex-start;
        flex-flow: row nowrap;
        overflow-x: hidden;
        margin-left: u(- $spacing-unit--sm);

        &--space {
            @include mq($from: $viewport--xxl) {
                justify-content: space-between;
            }
        }
    }

    &-item {
        display: inline-flex;
        flex-direction: column;
        width: u(230px);
        min-width: u(230px);
        margin: 0 u($spacing-unit--sm);

        &-image {
            @include aspect-ratio(1, 1);
            box-shadow: 0px 4px 20px 0px rgba($black, 0.05);

            picture {
                width: 100%;
                height: 100%;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        &-title {
            color: $primary-color;
            margin-top: u($spacing-unit * 1.5);
            margin-bottom: u($spacing-unit * .25);

            &--numbered {
                display: flex;
                justify-content: center;
                align-items: center;
                width: u(35px);
                height: u(35px);
                color: $white;
                font-size: u(16);
                background-color: $primary-color;
                border-radius: 50%;
                box-shadow:
                  inset 0 0 0 1.5px $primary-color,
                  inset 0 0 0 4px $white;

                span {
                    display: block;
                }
            }
        }

        p {
            word-break: break-word;
        }
    }

    // Controls
    &-controls {
        &.is-hidden {
            display: none;

            @include mq($until: $viewport--xxl) {
                display: flex;
                justify-content: flex-end;
            }
        }

        &.is-visible {
            display: flex !important;
            justify-content: flex-end;
        }
    }
}
